import React from "react";
import { useSelector } from "react-redux";
import p2pData from "./json/p2pFees.json";
export default function P2PFees() {
  const { switch_theme } = useSelector((state) => state.AuthReducer);
  return (
    <>
      <table
        className={
          switch_theme == "dark"
            ? "table table-dark table-striped"
            : "table table-light table-striped"
        }
      >
        <thead>
          <tr>
            <th scope="col" rowspan="3">
              Market
            </th>
            <th scope="col" colspan="3">
              Order Size
            </th>
            <th scope="col" colspan="2">
              Fee
            </th>
            <th scope="col" rowspan="3">
              Status
            </th>
          </tr>
          <tr>
            <th scope="col" colspan="2">
              Buy
            </th>
            <th scope="col">Sell</th>
            <th scope="col" rowspan="2">
              Buy
            </th>
            <th scope="col" rowspan="2">
              Sell
            </th>
          </tr>
          <tr>
            <th scope="col">Min</th>
            <th scope="col">Max</th>
            <th scope="col">Min</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {p2pData?.map((item, i) => {
            return (
              <>
                <tr key={i+"sadsap2p"}>
                  <td scope="row">{item?.token_pair_market}</td>
                  <td>{item?.order_min_buy}</td>
                  <td>{item?.order_max_buy}</td>
                  <td>{item?.order_sell_min}</td>
                  <td>{item?.fee_buy}%</td>
                  <td>{item?.fee_sell}%</td>
                  <td>
                    {" "}
                    <div
                      className={
                        switch_theme == "dark"
                          ? "badges-custom-success-dark"
                          : "badges-custom-success"
                      }
                    >
                      {item?.status}
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
