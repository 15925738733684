import React from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./components/Header";

export default function UpcomingProgram(props) {
  return (
    <div>
      <Header {...props} />
      <div className="col-lg-12">
       
          <ProfileSidebar {...props} />
       
        <div className="main-content">
          <div className="p-2 theme-color my-sidebox-shadow">
            <div className="mt-3">
              <h4 className="mb-3">Upcoming Program</h4>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="">
                  <h5 className="mb-0">Coming Soon</h5>
                <p className="my-0 text-muted"> Thankyou for your patience</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
