import React, { useState, useEffect } from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { user_logout } from "./redux/actions/authActions";

import {
  N_setGoogleAuth,
  N_get_settings,
  N_checkMobileStatus
} from "./redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
const speakeasy = require("speakeasy-latest");
var QRCode = require("qrcode");
export default function TwoFA(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.AuthReducer);
  const [authenticator, setAuthenticator] = useState(0);
  const [mobile_auth, setMAuthenticator] = useState(0);
  const [url, setUrl] = useState();
  const [userSecret, setUserSecret] = useState();
  const [temp_secret, setSecret] = useState([]);
  const { webData } = useSelector((state) => state.websiteDBReducer);

  useEffect(() => {
    N_get_settings(user?.params ? user.params.token : user.token)
      .then((d) => {
        if (d.status === 200) {
          setAuthenticator(d.params.authenticator_status);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    N_checkMobileStatus(user?.params ? user.params.token : user.token)
      .then((d) => {
        console.log(d.params.is_mobile);
        if (d.status === 200 && d.params.is_mobile === 1) {
          setMAuthenticator(1);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  });
  const generatSecret = () => {
    var secret = speakeasy.generateSecret({ length: 20 });
    setSecret(secret);
    QRCode.toDataURL(secret.otpauth_url, function (err, data_url) {
      setUrl(data_url);
      setUserSecret(secret.base32);
    });
  };
  const setAuthentication = (e, state, action) => {
    e.preventDefault();
    N_setGoogleAuth(
      temp_secret,
      state,
      user?.params ? user.params.token : user.token
    ).then((data) => {
      if (data.status === 200) {
        if (action === "g") {
          $(".modal-backdrop").hide();
          props.history.replace("/account_authenticator");
        } else {
          NotificationManager.success(data.message);
          window.location.reload();
        }
      } else {
        NotificationManager.error(data.message);
      }
    });
  };

  return (
    <div>
      <Header {...props} />
      <div className="col-lg-12">
     
          <ProfileSidebar {...props} />
        
        <div className="main-content">
          <div className="mt-3">
            <h4 className="mb-3">Two Factor Authentication</h4>
            <div className="card">
              <div className="card-body">
                <div className="hero_div">
                  <div
                    for="securityhigh"
                    className="d-flex align-items-center gap-3"
                  >
                    <div className="" style={{ cursor: "pointer" }}>
                      <input
                        id="securityhigh"
                        type="radio"
                        className="form-check-input"
                        name="security"
                        checked={authenticator == 2}
                        data-toggle="modal"
                        data-target="#modal2FA"
                        onClick={(e) => {
                          generatSecret();
                        }}
                      />
                    </div>

                    <div className="h6 p-0 m-0">
                      Authenticator App
                      <div className="h6 p-0 m-0 text-secondary">
                        Highly secure
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="hero_div"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    for="securityhigh"
                    className="d-flex align-items-center gap-3"
                  >
                    <div>
                      <input
                        type="radio"
                        className="form-check-input"
                        name="security"
                        checked={authenticator == 1}
                        data-toggle="modal"
                        data-target="#modal2FAMobile"
                        onClick={() =>
                          !mobile_auth
                            ? props.history.push("/mobile-verify")
                            : null
                        }
                      />
                    </div>

                    <div className="h6 p-0 m-0">
                      Mobile SMS{" "}
                      <div className="h6 p-0 m-0 text-secondary">
                        Moderately secure
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hero_div">
                  <div
                    className="d-flex align-items-center gap-3"                    
                  >
                    <div className="">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="security"
                        checked={authenticator == 0}
                        data-toggle="modal"
                        data-target="#modal2FAnone"
                      />
                    </div>
                    <div className="">
                      <div className="h6 p-0 m-0">
                        None{" "}
                        <div className="h6 p-0 m-0 text-secondary">
                          not secure
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="modal2FA"
                  className={`modal fade ${webData.bg_color}`}
                  role="dialog"
                >
                  <div className="modal-dialog">
                    <div className="modal-content light-theme-color">
                      <form>
                        <div className="modal-header">
                          <h5 className="modal-title" id="msg">Google Authentication</h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-dismiss="modal"
                          >
                          
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="form-group text-center">
                            <img src={url} />
                          </div>
                          <div className="form-group text-center">{userSecret}</div>
                        </div>
                        <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-dismiss="modal"
                          >
                            Close
                          </button>

                          <button
                            type="submit"
                            className="btn btn-warning"
                            onClick={(e) => {
                              setAuthentication(e, 0, "g");
                            }}
                          >
                            <i
                              className="loading-icon fas fa-spinner fa-spin"
                              id="loader"
                              style={{ display: "none" }}
                            ></i>
                            Yes
                          </button>
                         
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  id="modal2FAMobile"
                  className={`modal fade ${webData.bg_color}`}
                  role="dialog"
                >
                  <div className="modal-dialog">
                    <div className="modal-content light-theme-color">
                      <form>
                        <div className="modal-header">
                          <h5 className="page-title" id="msg">Mobile Authentication</h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-dismiss="modal"
                          >
                            
                          </button>
                        </div>
                        <div className="modal-body text-center">
                         <p> Are you sure for Mobile Authenticaton?</p>
                        </div>
                        <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-dismiss="modal"
                          >
                            Close
                          </button>

                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={(e) => {
                              setAuthentication(e, 1);
                            }}
                          >
                            <i
                              className="loading-icon fas fa-spinner fa-spin"
                              id="loader"
                              style={{ display: "none" }}
                            ></i>
                            Yes
                          </button>
                         
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  id="modal2FAnone"
                  className={`modal fade ${webData.bg_color}`}
                  role="dialog"
                >
                  <div className="modal-dialog">
                    <div className="modal-content light-theme-color">
                      <form>
                        <div className="modal-header">
                          <h3 id="msg">Authentication</h3>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body center">
                          Are you sure Disabled Authenticator?
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="btn btn-theme-color"
                            onClick={(e) => {
                              setAuthentication(e, 0);
                            }}
                          >
                            <i
                              className="loading-icon fas fa-spinner fa-spin"
                              id="loader"
                              style={{ display: "none" }}
                            ></i>
                            Yes
                          </button>
                          <button
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
