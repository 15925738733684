import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoginORSignup from "./LoginORSignup";
import { NotificationManager } from "react-notifications";
import "./order.css";
import Loader from "./Loader";
import { getUserBalance, getUserOrder } from "../redux/actions/coinDBAction";
import {
  N_cancleOrderById,
  N_completeOrder,
  N_pendingOrder,
} from "../redux/helpers/api_functions_new";
import { round } from "../redux/helpers/Math";
import {
  SET_USER_ORDER_CLOSE,
  SET_USER_ORDER_PENDING,
} from "../redux/constant";
import { FaFilter } from "react-icons/fa";

/**
 * This useEffect causing the error in the following code:
*   useEffect(() => {
   console.log("--- useEffect line 44 ---");
   // Memoize the filtered pending orders
   const filteredPendingOrders = user_order_pending.filter((order) =>
     isFilterActive ? order.currency_type === coin[0] : true
   );
 
   // Memoize the filtered close orders
   const filteredCloseOrders = user_order_close.filter((order) =>
     isFilterActive ? order.currency_type === coin[0] : true
   );
 
   // Update the state with the memoized values
   setpendingOrder(filteredPendingOrders);
   setcloseOrder(filteredCloseOrders);
 }, [user_order_pending, user_order_close, isFilterActive, coin]);
 *  
 */
export default function OrdersTab(props) {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const { user } = useSelector((state) => state.AuthReducer);
  const { webData } = useSelector((state) => state.websiteDBReducer);

  const { user_order_pending, user_order_close } = useSelector(
    (state) => state.coinDBReducer
  );
  const [pendingOrder, setpendingOrder] = useState([]);
  const [closeOrder, setcloseOrder] = useState([]);
  const coin = props.match.params.id.split("-");

  // State for the filter
  const [isFilterActive, setIsFilterActive] = useState(false);

  const handleFilter = (e) => {
    // Toggle the filter state
    setIsFilterActive(!isFilterActive);
  };

  useEffect(() => {
    // Memoize the filtered pending orders
    const filteredPendingOrders = user_order_pending.filter((order) =>
      isFilterActive ? order.currency_type === coin[0] : true
    );

    // Memoize the filtered close orders
    const filteredCloseOrders = user_order_close.filter((order) =>
      isFilterActive ? order.currency_type === coin[0] : true
    );

    // Only update the state if the filtered orders have changed
    if (
      JSON.stringify(filteredPendingOrders) !== JSON.stringify(pendingOrder) ||
      JSON.stringify(filteredCloseOrders) !== JSON.stringify(closeOrder)
    ) {
      setpendingOrder(filteredPendingOrders);
      setcloseOrder(filteredCloseOrders);
    }
  }, [user_order_pending, user_order_close, isFilterActive, coin]);

  useEffect(() => {
    const token = user?.params ? user.params.token : user.token;
    if (activeTab == 0 && token) {
      N_pendingOrder(token)
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: SET_USER_ORDER_PENDING,
              data: res.params.trade_history.pending,
              pending_order_loading: false,
            });
          } else {
            console.log("error to fetch open and close orders: ", res);
          }
        })
        .catch((e) => console.log(e));
    }
    if (activeTab == 1 && token) {
      N_completeOrder(token)
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: SET_USER_ORDER_CLOSE,
              data: res.params.trade_history.compleated,
              close_order_loading: false,
            });
          } else {
            console.log("error to fetch open and close orders: ", res);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [activeTab]);

  useEffect(() => {
    setpendingOrder(
      user_order_pending.sort(
        (a, b) => Number(b.timestamp) - Number(a.timestamp)
      )
    );
    setcloseOrder(
      user_order_close.sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
    );
  }, [user_order_pending, user_order_close]);

  return (
    <>
      <div className="card mt-2 mb-2">
        <div className="card-header p-0">
          <div className="row">
            <div className="col-md-4">
              <div className="nav nav-pills" id="nav-tab" role="tablist">
                <div
                  className={`nav-item nav-link ${
                    activeTab === 0 ? "active" : ""
                  }`}
                  id="nav-home-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                  onClick={() => setActiveTab(0)}
                >
                  Open Orders
                </div>
                <div
                  className={`nav-item nav-link ${
                    activeTab === 1 ? "active" : ""
                  }`}
                  id="nav-profile-tab"
                  data-toggle="tab"
                  onClick={() => setActiveTab(1)}
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Completed Orders
                </div>
              </div>
            </div>
            <div className="col-md-8 d-flex justify-content-end align-items-center">
              <div
                className="flex"
                onClick={handleFilter}
                style={{ marginRight: "10px", cursor:"pointer" }}
              >
                <FaFilter /> {isFilterActive?'Show':"Hide"} Other Pair
              </div>
            </div>
          </div>
        </div>

        <div className="order_height custom_scrollbar">
          {!isLoggedIn ? (
            <div className="tab-content orders">
              <LoginORSignup />
            </div>
          ) : null}
          {activeTab === 0 && isLoggedIn ? <div className=""></div> : null}
          {isLoggedIn ? (
            <>
              <div className="tab-content orders">
                <div
                  className={`tab-pane fade ${
                    activeTab === 0 ? "show active" : ""
                  }`}
                  id="open-order"
                >
                  <table className="exchange_tbl order-book-table table_striped">
                    <thead className="sticky_thead">
                      <tr>
                        <th>
                          <h6>Pair</h6>
                        </th>
                        <th>
                          <h6>Amount</h6>
                        </th>
                        <th>
                          <h6>Price</h6>
                        </th>
                        <th>
                          <h6>Total</h6>
                        </th>
                        <th>
                          <h6>Executed</h6>
                        </th>
                        <th>
                          <h6>Completed On</h6>
                        </th>
                        <th>
                          <h6>Delete</h6>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {pendingOrder && pendingOrder?.length > 0
                        ? pendingOrder.map((d, index) => {
                            return (
                              <OrderRow
                                {...d}
                                key={index}
                                // deleteOrder={(order_id, order_type) =>
                                //   deleteOrder(order_id, order_type)
                                // }
                                user_id={
                                  user?.params ? user.params.token : user.token
                                }
                              />
                            );
                          })
                        : null}
                    </tbody>
                  </table>

                  {pendingOrder?.length === 0 ? (
                    <div className="text-center py-5 text-muted" colspan="3">
                      <div>
                        <i class="fa fa-folder-open-o fs-2 text-muted"></i>
                      </div>
                      <div className="fs-12">No Open Orders !</div>
                    </div>
                  ) : null}
                  {loading ? <Loader /> : null}
                </div>

                <div
                  id="order-history"
                  className={`tab-pane fade ${
                    activeTab === 1 ? "show active" : ""
                  }`}
                >
                  <table className="order-book-table exchange_tbl">
                    <thead className="sticky_thead">
                      <tr>
                        <th>
                          <h6>Pair</h6>
                        </th>
                        <th>
                          <h6>Amount</h6>
                        </th>
                        <th>
                          <h6>Price</h6>
                        </th>
                        <th>
                          <h6>Total</h6>
                        </th>
                        <th>
                          <h6>Executed</h6>
                        </th>
                        <th>
                          <h6>Completed On</h6>
                        </th>
                        <th>
                          <h6>Action</h6>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {closeOrder && closeOrder?.length > 0
                        ? closeOrder.map((d, index) => {
                            return (
                              <OrderRow {...d} key={index} order_type={1} />
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                  {closeOrder?.length === 0 ? (
                    <div className="text-center py-5 text-muted" colspan="3">
                      <div>
                        <i class="fa fa-folder-open-o fs-2 text-muted"></i>
                      </div>
                      <div className="fs-12">No Completed Orders !</div>
                    </div>
                  ) : null}
                  {loading ? <Loader /> : null}
                  {/* "READ MORE" button in the top-right corner */}
                  {/* <div className="d-flex justify-content-center">
                    <button className="btn btn-dark mt-3">READ MORE</button>
                  </div> */}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

function OrderRow(props) {
  const [popup, setpopup] = useState(false);
  const [spin, setspin] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.AuthReducer);
  let progress_width = 0;
  let back = "rgb(16 129 53 / 10%)";
  const [dis, setdis] = useState(false);
  if (props["total_buy"] === undefined) {
    progress_width = (props.total_executed * 100) / props.volume;
    back = props.type === "buy" ? "rgba(35, 172, 80, 0.4)" : "#81101026";
  } else {
    progress_width = (props.total_executed * 100) / props.volume;
    back = "rgb(16 129 53 / 10%)";
  }

  const deleteOrder = (order_id, user_id) => {
    const token = user?.params ? user.params.token : user.token;
    setspin("spinner-border spinner-border-sm");
    N_cancleOrderById(token, order_id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getUserOrder(user_id, props.type));
          dispatch(getUserBalance(user_id));
          setTimeout(() => {
            setspin("");
            setpopup(false);
            setdis(false);
          }, 1000);
          // setLoading(false);
          NotificationManager.success(res.message);
        } else {
          NotificationManager.error(res.message);
        }
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  // function getDateTime(props) {
  //   let ddate = props.order_date;
  //   if (ddate === 0) {
  //     ddate = props.execution_date;
  //   }
  //   return ddate;
  // }

  return (
    <>
      {popup ? (
        <>
          <div
            style={{
              position: "absolute",
              height: "43%",
              width: "99%",
              display: "flex",
              flexDirection: "column",
              zIndex: 200,
              top: "45px",
              left: "2px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "100%",
                zIndex: 1000,
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
              }}
            >
              <div className="row" style={{ width: "220px" }}>
                <div className="shadow-sm p-0">
                  <div className=" card-body bg-white text-center">
                    <div>
                      <h6 className="text-red fs-12">Want to Delete Order ?</h6>
                      <div className="mt-3">
                        <button
                          type="button"
                          className="btn btn-light btn-sm me-2"
                          onClick={() => {
                            if (!dis) {
                              setpopup(false);
                            }
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            if (!dis) {
                              setdis(true);
                              deleteOrder(
                                props.order_id,
                                props.user_id,
                                props["total_buy"] === undefined
                                  ? "sell"
                                  : "buy"
                              );
                            }
                          }}
                        >
                          {dis ? (
                            <span
                              className={`${spin} mx-2`}
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : null}
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <tr className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>{props?.currency_type?.toUpperCase()}</td>
        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>{round(props?.volume)}</td>
        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>{round(props?.raw_price)}</td>

        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>{round(props?.volume * props?.raw_price)}</td>
        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
          {props.total_executed}
        </td>
        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
          {new Date(Number(props.timestamp)).toLocaleString()}
        </td>

        <td>
          {props?.order_type !== 1 ? (
            <div
              title="Cancel Order"
              className=""
              onClick={() => {
                setpopup(true);
              }}
            >
              <i className="fa fa-trash text-danger"></i>
            </div>
          ) : props?.status == "c" ? (
            <span className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>Executed</span>
          ) : (
            <span className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>Cancel</span>
          )}
        </td>
      </tr>
    </>
  );
}
