import React, { useState } from "react";
import Header from "./components/Header";
import "./login.css";
import { isEmail } from "./redux/helpers/form-validator.functions";
import { user_forget } from "./redux/actions/authActions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
export default function Forget(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const onSubmit = (e) => {
    e.preventDefault();
    if (email!='' || phone!='') {
      setLoading(true);
      dispatch(
        user_forget(
          email,
          phone,
          () => {
            setLoading(false);
            props.history.replace("/forget-password");
          },
          () => setLoading(false)
        )
      );
    }
  };
  return (
    <>
      <Header {...props} />
      <div class="container-fluid">
        <div class="row no-gutter">
          <div class="col-md-6 d-none d-md-flex bg-image">
            <img src="/img/04.png" className="login-img"/>
          </div>

          <div class="col-md-6 bg-login">
            <div class="login d-flex align-items-center py-5">
             <div className="container">
             <div className="row">
                <div class="col-lg-10 col-xl-7 m-auto signupform">
                <h3 class="mt-3"> Forget Password</h3>
                    <p class="text-muted mb-4 mt-0">
                      Enter email,  we'll send you new password in your inbox
                    </p>

                  <form
                    className=""
                    method="post"
                    id="signupform"
                    onSubmit={onSubmit}
                  >
                    
                    <div className="form-group">
                      <small id="msg" style={{ fontSize: "15px" }}>
                        Error message
                      </small>
                    </div>  
                    <div className="form-group">
                      {/* <label htmlFor="user_email">EMAIL</label> */}
                      <input
                        type="email"
                        name="user_email"
                        required=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        id="user_email"
                        placeholder="example@gmail.com"
                      />
                      <i className="fas fa-check-circle"></i> 
                      <i className="fas fa-exclamation-circle"></i>
                      <small>Error message</small>
                    </div>
                    OR
                    <div className="form-group">
                      {/* <label htmlFor="user_email">EMAIL</label> */}
                      <input
                        type="number"
                        name="user_phone"
                        required=""
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="form-control"
                        id="user_phone"
                        placeholder="999999999"
                      />
                      <i className="fas fa-check-circle"></i> 
                      <i className="fas fa-exclamation-circle"></i>
                      <small>Error message</small>
                    </div>
                   <div className="d-grid"> <button type="submit" id="btn_submit" className="btn btn-primary btn-lg">
                      <i className="loading-icon fas fa-spinner fa-spin me-2 h"></i>
                      <span id="reg">Send</span>
                    </button></div>
                    <div className="text-center text-muted mt-2"> 
                        <Link className="text-primary" to="/login"> Back to Login Page </Link>
                      </div>
                  </form>
                </div>
              </div>
             </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
