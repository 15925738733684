import React from "react";
import { useSelector } from "react-redux";
import inrMarketsData from "./json/inrMarkets.json";

export default function InrMarkets() {
  const { switch_theme } = useSelector((state) => state.AuthReducer);
  return (
    <>
      <table
        className={
          switch_theme == "dark"
            ? "table table-dark table-striped"
            : "table table-light table-striped"
        }
      >
        <thead>
          <tr>
            <th scope="col">MARKET</th>
            <th scope="col">MINIMUM ORDER SIZE</th>
            <th scope="col">STATUS</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {inrMarketsData?.map((item, i) => {
            return (
              <>
                <tr key={i+"ASDAAS"}>
                  <td scope="row">{item?.market_pair}</td>
                  <td>{item?.min_order_size}</td>
                  <td>
                    {" "}
                    <div
                      className={
                        item?.status=="Active" ? switch_theme == "dark"
                          ?  "badges-custom-success-dark"
                          : "badges-custom-success":"badges-custom-danger"
                      }
                    >
                      {item?.status}
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
