import {
  AUTH_FORGET,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  SEND_OTP,
  F_OTP,
  SEND_AUTH,
  SWITCH_THEME,
} from "../constant";
let token = localStorage.getItem("exchange_token");
let thme = localStorage.getItem("exchange_inrx_theme");
const initialState = {
  user: {
    id: 0,
    email: "",
    kyc: 0,
    verify: 0,
    // token: session ? session : "",
    token: token ? token : "",
  },
  otp_send: false,
  isLoggedIn: token ? true : false,
  switch_theme: thme?thme:"light",
};

function saveLogoutTextFile() {
  const text = 'LOGGING OUT';

  // Create a Blob with the text content
  const blob = new Blob([text], { type: 'text/plain' });

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element for downloading the file
  const a = document.createElement('a');
  a.href = url;
  a.download = 'logout.txt';

  // Trigger a click event on the link to download the file
  a.click();

  // Clean up resources
  window.URL.revokeObjectURL(url);
}

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN:
      localStorage.setItem("exchange_token", action.data.params.token);
      return {
        ...state,
        user: { ...action.data },
        otp_send: false,
        isLoggedIn: true,
      };
    case SEND_OTP:
      return {
        ...state,
        otp_send: true,
        user: { ...action.data },
      };
    case F_OTP:
      return {
        ...state,
        otp_send: true,
        user: { ...action.data },
      };
    case SEND_AUTH:
      return {
        ...state,
        otp_send: true,
        user: { ...action.data },
      };
    case AUTH_FORGET:
      return {
        ...state,
      };

    case AUTH_LOGOUT:
      localStorage.removeItem("exchange_token");
      saveLogoutTextFile()
      return {
        ...state,
        isLoggedIn: false,
      };
    case SWITCH_THEME:
      localStorage.setItem("exchange_inrx_theme", action.data);
      return {
        ...state,
        switch_theme: action.data,
      };
    
    default:
      return {
        ...state,
      };
  }
}
