import React from "react";
import { useSelector } from "react-redux";
import { BsArrowUpShort } from "react-icons/all";
import { BsArrowDownShort } from "react-icons/all";
import {
  FaCoins,
  BiGitCompare,
  HiCubeTransparent
} from "react-icons/all";
import Card from "./Card";
import AOS from "aos";
import "aos/dist/aos.css";

export default function MarketTrend() {
  AOS.init();
  const { coins } = useSelector((state) => state.coinDBReducer);
  let coins1 = Object.values(coins);
  // console.log(coins1)
  const market_child =
    coins1 &&
    coins1.map((item, index) => {
      console.log("item",item)
      let usdtprize = coins1 && coins1.find((item) => item.symbol == "INR");
      return (
        <>
        
          {item.symbol == "RUBY"||
          item.symbol == "BTC" ||
          item.symbol == "BNB" ||
          item.symbol == "ETH" ||
          item.symbol == "TRX" ? (
            <tr>
              <td>
                <img
                  src={item.icon}
                  alt="coin symbole"
                  className="market_coin_img"
                  //style={{ width: "50px" }}
                />
                {item.symbol}
                <span className="name_coin ms-2">{item.name}</span>
              </td>
              <td>
              
                {(
                  item.inr_price
                ).toFixed(8)}
              </td>
              <td>
                <span
                  className={
                    item.direction_inr === "up"
                      ? "text-green "
                      : "text-red"
                  }
                >
                  {item.price_change_percentage_1h_inr}%{" "}
                  {item.direction_inr === "up" ? (
                    <BsArrowUpShort />
                  ) : (
                    <BsArrowDownShort />
                  )}
                </span>
              </td>
              <td>
                <a href={`/exchange/${item.symbol.toLowerCase()}-ruby`} className="btn btn-outline-primary">
                  Trade
                </a>
              </td>
            </tr>
          ) : (
            ""
          )}
        </>
      );
    });
  return (
    <>
      <div className="spacer">
        <div className="container">
          <div className="para_heading">Market Trend</div>
          <div className="market_trend_section">
            <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
              <table className="table home_table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Last Price</th>
                    <th>24h Change</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{market_child}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="spacer features_section">
        <div className="container">
          <div className="para_heading text-center">Key Features</div>
          <div className="row d-flex align-center justify-content-center">
            <div className="col-lg-4 col-md-6 col-12 d-flex justify-content-center">
              <div className="card bg-transparent border-0">
                <div className="card-body padding_5">
                  <FaCoins className="icons" />
                  <h5 className="">Real-Time Price</h5>
                  <p>
                    Get latest crypto prices, interactive charts, and technical
                    analysis for digital currencies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 d-flex justify-content-center">
              <div className="card bg-transparent border-0">
                <div className="card-body padding_5">
                  <BiGitCompare className="icons" />
                  <h5>Comparisons</h5>
                  <p>
                    Compare performance of your cryptocurrencies with Coinread.
                    Graphs, market cap, price, circulating supply, max supply
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 d-flex justify-content-center">
              <div className="card bg-transparent border-0">
                <div className="card-body padding_5">
                  <HiCubeTransparent className="icons" />
                  <h5>Transparent</h5>
                  <p>
                    Find the crypto data you need whether you’re looking to
                    trade on a new to the exchange, invest in a fresh currency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="spacer mt-4 app_section">
        <div className="container text-center">
          <div className="col-md-12 mb-5">
            <h2 className="para_heading">Trade anytime, anywhere </h2>
            <p>
              Download our newly launched app available on Android and Apple
              Devices
            </p>
          </div>
         
          <div className="row d-flex align-center justify-content-center">
            <div
              className="col-lg-8 col-md-6 col-12 d-flex justify-content-center"
              data-aos="fade-up"
            >
              <img src="/img/metatrader-4.png" className="img-fluid" />
            </div>
          </div>
          <div className="d-flex align-center justify-content-center my-3 gap-3">
            {" "}
            <div>
              <img src="/img/apple-app.svg" className="img-fluid" />{" "}
            </div>
            <div>
              <img src="/img/get-it-on-google-play.png"  style={{ height:'40px' }} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
