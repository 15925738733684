import React from "react";
import { useSelector } from "react-redux";
import DepositWithdrewFees from "./components/Fees/deposit-withdrew-fees";
import InrMarkets from "./components/Fees/InrMarkets";
import Other_Markets from "./components/Fees/Other_Markets";
import P2PFees from "./components/Fees/p2pFees";
// import { useSelector } from 'react-redux';
import Header from "./components/Header";
import "./fees.css";
import Footer from "./HomeComp/Footer";
export default function Fee(props) {
    const { switch_theme } = useSelector(
        (state) => state.AuthReducer
      );
  return (
    <>
      <div>
        <div>
          {" "}
          <Header {...props} />
        </div>

        <div className="container pt-5 pb-5 px-4 mt-2 ">
          <ul
            className="nav nav-pills mb-3 text-center d-flex justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active fw-bold"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Deposit & Withdrawal Fees
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link fw-bold"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Trading Fees
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link fw-bold"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                P2P Fees
              </button>
            </li>
          </ul>
          <div className={switch_theme == "dark"? "alert alert-dark-1"  :"alert alert-success"} role="alert">
            <h4 className="alert-heading">Important!</h4>
            <p>
              From 1 July 2022, TDS (Tax Deducted at Source) of 1% (5% in
              exceptional cases) of the trade value will be deducted on each
              trade as per the Government’s guidelines issued on Virtual Digital
              Asset (VDA) transfers. This TDS can be claimed while filing your
              ITR.
            </p>
            <hr />
            <p className="mb-0 text-primary">Learn more</p>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabindex="0"
            >
             <DepositWithdrewFees/>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <div>
                <ul
                  class="nav nav-pills mb-3 d-flex justify-content-center"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link-1 active fw-bold"
                      id="pills-home-tab-1"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home-1"
                      type="button"
                      role="tab"
                      aria-controls="pills-home-1"
                      aria-selected="true"
                    >
                      INR Markets
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link-1 fw-bold"
                      id="pills-profile-tab-1"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile-1"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile-1"
                      aria-selected="false"
                    >
                      Other Markets
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home-1"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab-1"
                    tabindex="0"
                  >
                    <div>
                      <div class="nlRFu-1">
                        <span
                          color="var(--color-text-l0)"
                          class="sc-bdfBQB XYppe-1"
                        >
                          <ul className="bullet-icon">
                            <li>
                              The trading fee is determined based on your last
                              30-day trading volume in INR pairs and your
                              current RBC holdings.
                            </li>
                            <li>
                              Your trading fee slab will be updated on a daily
                              basis. <a href="/login?returnTo=/fees">Log in</a>{" "}
                              to see your Fee.
                            </li>
                          </ul>
                        </span>
                        <span class="sc-bdfBQB fZyMhB-1">
                          <a
                            href="#"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Learn more
                          </a>
                        </span>
                      </div>
                      <div className="pt-2 fees-info-table table-responsive">
                        <table className={switch_theme == "dark" ? "table table-dark":"table table-light"}>
                        <thead>
                        <tr>
                        <th rowspan="2" colspan="1" width="200px">
                        <div>30D Trading Volume</div>
                        <div>in INR Pairs**</div>
                        </th>
                        <th colspan="4">Trading Fees based on RBC Holdings on CTSKOLA*</th>
                        </tr>
                        <tr>
                        <th>0 - 500 RBC</th>
                        <th>500 - 10,000 RBC</th>
                        <th>10,000 - 50,000 RBC</th>
                        <th>50,000 + RBC</th>
                        </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            <tr>
                            <td scope="row">₹ 0 - 5 LACS</td>
                            <td>0.40%</td>
                            <td>0.34%</td>
                            <td>0.30%</td>
                            <td>0.20%</td>
                            </tr>
                            <tr>
                            <td scope="row">₹ 5LACS - 1CR</td>
                            <td>0.35%</td>
                            <td>0.30%</td>
                            <td>0.26%</td>
                            <td>0.18%</td>
                            </tr>
                            <tr>
                            <td scope="row">₹ 1CR - 2CR	</td>
                            <td>0.30%</td>
                            <td>0.26%</td>
                            <td>0.23%</td>
                            <td>0.15%</td>
                            </tr>
                            <tr>
                            <td scope="row">₹ 2CR - 3.5CR</td>
                            <td>0.25%</td>
                            <td>0.21%</td>
                            <td>0.19%</td>
                            <td>0.13%</td>
                            </tr>
                            <tr>
                            <td scope="row">₹ 3.5CR+</td>
                            <td>0.20%</td>
                            <td>0.17%</td>
                            <td>0.15%</td>
                            <td>0.10%</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div>
                        <span className="d-flex justify-content-end">*GST and taxes as applicable</span>
                        <span className="d-flex justify-content-end">**Crypto to Crypto & P2P volume not considered</span>

                    </div>
                    <div className="pt-3 fees-info-table table-responsive">
                        <InrMarkets/>
                    </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-profile-1"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab-1"
                    tabindex="0"
                  >
                    <div>
                        <div className="fs-9">Based on your RBC holding at the time of the trade, your trading fee rate will be determined as follows:</div>
                        <div className="pt-2 fees-info-table table-responsive">
                        <table className={switch_theme == "dark" ? "table table-dark table-borderless" :"table table-light table-borderless"}>
                        <tbody className={switch_theme == "dark" ?"border-color-dark":"border"}>
                            <tr>
                            <th scope="row">RBC HOLDINGS</th>
                            <th>RBC HOLDINGS</th>
                            <th>500 - 10,000 RBC</th>
                            <th>10,000 - 50,000 RBC	</th>
                            <th>50,000 + RBC</th>
                            </tr>
                            <tr>
                            <th scope="row">TRADING FEE PAYABLE	</th>
                            <th>0.2%</th>
                            <th>0.17%</th>
                            <th>0.15%</th>
                            <th>0.1%</th>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div className="pt-2 fees-info-table table-responsive">
                        <Other_Markets/>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
              tabindex="0"
            >
              <div className="pt-2 fees-info-table table-responsive">
              <P2PFees/>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <Footer />
        </div>
      </div>
    </>
  );
}
