import React from "react";
import { useSelector } from "react-redux";
import otherMarketData from "./json/otherMarkets.json";

export default function Other_Markets() {
  const { switch_theme } = useSelector((state) => state.AuthReducer);
  return (
    <>
      <table
        className={
          switch_theme == "dark"
            ? "table table-dark table-striped"
            : "table table-light table-striped"
        }
      >
        <thead>
          <tr>
            <th rowspan="2">Market</th>
            <th rowspan="2">Minimum Order Size</th>
            <th colspan="2">Buy</th>
            <th colspan="2">Sell</th>
            <th rowspan="2">Status</th>
          </tr>
          <tr>
            <th>Maker Fee*</th>
            <th>Taker Fee*</th>
            <th>Maker Fee*</th>
            <th>Taker Fee*</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {otherMarketData?.map((item, i) => {
            return (
              <>
                <tr key={i+"sdasda"}>
                  <td scope="row">{item?.market}</td>
                  <td>{item?.min_order_size}</td>
                  <td>{item?.buy_maker_fee}%</td>
                  <td>{item?.buy_taker_fee}%</td>
                  <td>{item?.sell_maker_fee}%</td>
                  <td>{item?.sell_taker_fee}%</td>
                  <td>
                    {" "}
                    <div
                      className={
                         item?.status == "Active" ? switch_theme == "dark"
                          ? "badges-custom-success-dark"
                          : "badges-custom-success" : "badges-custom-danger"
                      }
                    >
                      {item?.status}
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
