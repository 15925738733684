import React from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

export default function StartTrade() {
  AOS.init({ duration: 1000 });
  return (
    <>
      <div className="container-fluid ">
        <div className="features_section mt-4">
          <div className="container">
            <div className="col-md-12 mb-5">
              <h2 className="para_heading text-center"> Follow Us on Social Media</h2>
              <p className="text-center"> Stay up-to-date with our latest news </p>
            </div>
            <div className="row d-flex align-center justify-content-center">
              <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center">
                <div class="wrapper">
                  <a href="https://www.facebook.com/CTSKOLA" target="_blank">
                    <div class="bg-ico" id="facebook">
                      <i class="fab fa-facebook social  facebook fa-3x"></i>
                    </div>
                  </a>
                  {/* <a href="#">
                    <div class="bg-ico" id="pinterest">
                      <i class="fab fa-pinterest social  pinterest fa-3x"></i>
                    </div>
                  </a> */}
                  <a href="https://twitter.com/CtsKola" target="_blank">
                    <div class="bg-ico" id="twitter">
                      <i class="fab fa-twitter social  twitter fa-3x"></i>
                    </div>
                  </a>
                  <a href="https://www.instagram.com/ctskola/" target="_blank">
                    <div class="bg-ico" id="instagram">
                      <i class="fab fa-instagram social  instagram fa-3x"></i>
                    </div>
                  </a>
                  {/* <a href="#">
                    <div class="bg-ico" id="linkedin">
                      <i class="fa fa-linkedin social  linkedin fa-3x"></i>
                    </div>
                  </a> */}
                  <a href="/">
                    <div class="bg-ico" id="whatsapp">
                      <i class="fab fa-whatsapp social  whatsapp fa-3x"></i>
                    </div>
                  </a>
                  <a href="https://www.youtube.com/@ctskola" target="_blank">
                    <div class="bg-ico" id="youtube">
                      <i class="fab fa-youtube social  youtube fa-3x"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
