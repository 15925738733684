import React, { useEffect } from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./components/Header";
import InrDeposite from "./InrDeposite";

import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import { N_getUserBankDetails } from "./redux/helpers/api_functions_new";
export default function UserPayment(props) {
  const { user } = useSelector((state) => state.AuthReducer);
  const [bankStatus, setBankStatus] = React.useState(-1);
  const [bankDetails, setBankDetails] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    N_getUserBankDetails(user?.params ? user.params.token : user.token)
      .then((data) => {
        if (data.status === 200) {
          setBankStatus(data.params.bank_details.status);
          if (data.params.bank_details.status == 1)
            setBankDetails(data.params.bank_details);
        } else if (data.status === 400) {
          setBankStatus(data.params.bank_status);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Header {...props} />

      <div className="col-lg-12">
        
          <ProfileSidebar {...props} />
      
        <div className="main-content">
          <div className="mt-3">
            <h4 className="mb-3">Payment Option</h4>

            <article>
              {loading ? <Loader /> : null}
              {!loading && bankStatus == 1 ? (
                <>
                  <div className="card">
                    <div className="card-body">
                      <div className="row mt-2 mb-2">
                        <h6 className="col-md-12 page-title">
                          Your bank account details for IMPS payments
                        </h6>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <table className="table table-hover table-bordered">
                            <thead className="bg-light">
                              <tr>
                                <th className="tdCenter">Account Number</th>
                                <th className="tdCenter">IFSC Code</th>
                                <th className="tdCenter">Account Type</th>
                                <th className="tdCenter">Status</th>
                                <th className="tdCenter">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="tdCenter">
                                  {bankDetails.account_number}
                                </td>
                                <td className="tdCenter">
                                  {" "}
                                  {bankDetails.ifsc}{" "}
                                </td>
                                <td className="tdCenter">
                                  {" "}
                                  {bankDetails.account_type}{" "}
                                </td>
                                <td className="tdCenter">Active</td>
                                <td className="tdCenter">
                                  <button
                                    type="button"
                                    class="btn-light border-0 bg-transparent"
                                    onClick={() => {
                                      setBankStatus(2);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-pencil-square"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                      <path
                                        fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                      ></path>
                                    </svg>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {!loading && bankStatus == -1 ? (
                <div className="h5 p-2">
                  Your Bank details under Review for 72 Hours .{" "}
                </div>
              ) : null}
              {!loading && (bankStatus == 0 || bankStatus == 2) ? (
                <InrDeposite />
              ) : null}
            </article>
          </div>
        </div>
      </div>
    </>
  );
}
