import React from "react";
import { BsFacebook, BsTelegram, BsInstagram } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Footer() {
  AOS.init({ duration: 1000 });
  return (
    <>
      <div className="container-fluid py-5 footer_div">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-logo">
                <img src="../img/ctskola-0.png" alt="logo" />
              </div>
              <p className="fs-12 pt-2 pe-5">
                A crypto exchange, or a digital currency exchange (DCE), is a
                business that allows customers to trade cryptocurrencies for
                other assets, such as conventional fiat money or other digital
                currencies.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h5>About Us</h5>
              <ul className="footer_list">
                <li>
                  <a href="/about">About</a>
                </li>

                <li>
                  <a href="/downloads">Downloads</a>
                </li>

                <li>
                  <a>Business Contacts</a>
                </li>
                <li>
                  <a href="/community">Community</a>
                </li>
                <li>
                  <a href="/fee">Fees</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 my-2">
              <h5>Service</h5>
              <p>INR Trade</p>
              <p>Request Token</p>
              <p>Unique trade</p>
              <p>Safe trade</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 my-2 mmysocial">
              <h5>Social Links </h5>
              <div>
                <ul className="footer_list">
                  <li>
                    <a
                      className=""
                      href="https://www.facebook.com/CTSKOLA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsFacebook />
                      <span className="align-top ms-2">Facebook</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className=""
                      href="https://twitter.com/CtsKola"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AiFillTwitterCircle />
                      <span className="align-top ms-2">Twitter</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className=""
                      href="https://t.me/+ubgDH4F2B-ZiMDVl/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsTelegram />
                      <span className="align-top ms-2">Telegram</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className=""
                      href="https://www.instagram.com/ctskola/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsInstagram />
                      <span className="align-top ms-2">Instagram</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ backgroundColor: "white", width: "100%" }} />
        <div className="d-flex justify-content-center">
          <span className="" style={{ padding: "10px" }}>
            © 2022 Ctskola.
          </span>
          <a
            style={{
              fontWeight: "400",
              color: "#CDCFD4",
              padding: "10px",
              cursor: "pointer",
            }}
            href="/privacy"
          >
            Privacy policy
          </a>
          <a
            style={{
              fontWeight: "400",
              color: "#CDCFD4",
              padding: "10px",
              cursor: "pointer",
            }}
            href="https://ctskola.io/termsandconditions.pdf"
            target="_blank"
          >
            Terms & Conditions
          </a>
          <a
            style={{
              fontWeight: "400",
              color: "#CDCFD4",
              padding: "10px",
              cursor: "pointer",
            }}
            href="/api-docs"
            target="_blank"
          >
            API documentation
          </a>
        </div>
      </div>
    </>
  );
}
