import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  N_cancleOrderById,
  N_completeOrder,
  N_pendingOrder,
} from "./redux/helpers/api_functions_new";
import { SET_USER_ORDER_CLOSE, SET_USER_ORDER_PENDING } from "./redux/constant";
import { getUserBalance, getUserOrder } from "./redux/actions/coinDBAction";
import { NotificationManager } from "react-notifications";
import { round } from "./redux/helpers/Math";
import Loader from "./components/Loader";
import "./pending_completeOrder.css";
import Pagination from "react-bootstrap/Pagination";

export default function Pending_CompleteOrder(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [closeOrder, setcloseOrder] = useState([]);
  const [pendingOrder, setpendingOrder] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState(false);

  // Pagination state variables
  const [currentCloseOrderPage, setCurrentCloseOrderPage] = useState(1);
  const itemsPerPage = 5; // Set the number of items per page here
  const [totalCountPendingOrder, setTotalCountPendingOrder] = useState(0);
  const [totalCountCloseOrder, setTotalCountCloseOrder] = useState(0);

  const closeOrderTotalPages = Math.ceil(totalCountCloseOrder / itemsPerPage);
  const pendingOrderTotalPages = Math.ceil(
    totalCountPendingOrder / itemsPerPage
  );

  const { user_order_close, user_order_pending } = useSelector(
    (state) => state.coinDBReducer
  );

  const [currentPendingOrderPage, setCurrentPendingOrderPage] = useState(1);

  // Function to fetch data based on pagination parameters
  const fetchPendingOrders = (token, page, limit) => {
    setLoading(true);
    N_pendingOrder(token, "", "", page, limit)
    .then((res) => {
        if (res.status === 200) {
          setTotalCountPendingOrder(res.params.totalCount);
          dispatch({
            type: SET_USER_ORDER_PENDING,
            data: res.params.trade_history.pending,
            close_order_loading: false,
          });
        } else {
          console.log("error to fetch open and close orders: ", res);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const token = user?.params ? user.params.token : user.token;
    if (activeTab == 0 && token) {
      // Fetch data for the current page and itemsPerPage
      fetchPendingOrders(token, currentPendingOrderPage, itemsPerPage);
    }
  }, [activeTab, currentPendingOrderPage]);

  // Function to fetch data based on pagination parameters
  const fetchCloseOrders = (token, page, limit) => {
    setLoading(true);
    N_completeOrder(token, "", "", page, limit)
      .then((res) => {
        if (res.status === 200) {
          setTotalCountCloseOrder(res.params.totalCount);
          dispatch({
            type: SET_USER_ORDER_CLOSE,
            data: res.params.trade_history.compleated,
            close_order_loading: false,
          });
        } else {
          console.log("error to fetch open and close orders: ", res);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const token = user?.params ? user.params.token : user.token;
    if (activeTab == 1 && token) {
      // Fetch data for the current page and itemsPerPage
      fetchCloseOrders(token, currentCloseOrderPage, itemsPerPage);
    }
  }, [activeTab, currentCloseOrderPage]); // Update data when the tab or page changes

  useEffect(() => {
    setcloseOrder(
      user_order_close.sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
    );
  }, [user_order_close]);

  useEffect(() => {
    setpendingOrder(
      user_order_pending.sort(
        (a, b) => Number(b.timestamp) - Number(a.timestamp)
      )
    );
  }, [user_order_pending]);

  // Calculate hasMorePendingOrders and hasMoreCloseOrders
  const hasMorePendingOrders =
    currentPendingOrderPage * itemsPerPage < user_order_pending.length;
  const hasMoreCloseOrders =
    currentCloseOrderPage * itemsPerPage < user_order_close.length;

  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };

  return (
    <div>
      <Header {...props} />

      <div className="container mt-5">
        <h2 className="text-center para_heading">Your Orders</h2>

        <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
          <Nav justify variant="tabs" className="mb-3">
            {/* Tab for Pending Orders */}
            <Nav.Item>
              <Nav.Link eventKey="0">Pending Orders</Nav.Link>
            </Nav.Item>

            {/* Tab for Complete Orders */}
            <Nav.Item>
              <Nav.Link eventKey="1">Complete Orders</Nav.Link>
            </Nav.Item>
          </Nav>

          {/* Tab Content */}
          <Tab.Content>
            {/* Complete Orders Tab */}
            <Tab.Pane eventKey="0">
              <div className="market_trend_section">
                <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
                  <table className="table home_table">
                    <thead>
                      <tr>
                        <th>Pair</th>
                        <th>Amount</th>
                        <th>Price</th>
                        <th>Total</th>
                        <th>Executed</th>
                        <th>Completed</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      {pendingOrder && pendingOrder?.length > 0 ? (
                        pendingOrder.map((d, index) => (
                          <OrderRow {...d} key={index} order_type={1} />
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="7"
                            className="text-center py-5 text-muted"
                          >
                            <div>
                              <i className="fa fa-folder-open-o fs-2 text-muted"></i>
                            </div>
                            <div className="fs-12">No Pending Orders!</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {loading && <Loader />}
                </div>
                {pendingOrder.length > 0 ? (
                  <div className="container mt-4 text-center">
                    <div className="btn-group" role="group">
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          setCurrentPendingOrderPage((prevPage) =>
                            Math.max(prevPage - 1, 1)
                          )
                        }
                        disabled={currentPendingOrderPage === 1}
                      >
                        Previous
                      </button>
                      <span className="btn btn-secondary">
                        Page {currentPendingOrderPage}
                      </span>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          setCurrentPendingOrderPage((prevPage) => prevPage + 1)
                        }
                        disabled={
                          currentPendingOrderPage === pendingOrderTotalPages
                        }

                        // Use a boolean flag to indicate if there is more data
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </Tab.Pane>
            {/* Complete Orders Tab */}
            <Tab.Pane eventKey="1">
              <div className="market_trend_section">
                <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
                  <table className="table home_table">
                    <thead>
                      <tr>
                        <th>Pair</th>
                        <th>Amount</th>
                        <th>Price</th>
                        <th>Total</th>
                        <th>Executed</th>
                        <th>Completed</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      {closeOrder && closeOrder?.length > 0 ? (
                        closeOrder.map((d, index) => (
                          <OrderRowCompleted
                            {...d}
                            key={index}
                            order_type={1}
                          />
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="7"
                            className="text-center py-5 text-muted"
                          >
                            <div>
                              <i className="fa fa-folder-open-o fs-2 text-muted"></i>
                            </div>
                            <div className="fs-12">No Completed Orders!</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {loading && <Loader />}
                </div>

                {closeOrder.length > 0 ? (
                  <div className="container mt-4 text-center">
                    <div className="btn-group" role="group">
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          setCurrentCloseOrderPage((prevPage) =>
                            Math.max(prevPage - 1, 1)
                          )
                        }
                        disabled={currentCloseOrderPage === 1}
                      >
                        Previous
                      </button>
                      <div className="btn btn-secondary">
                        Page {currentCloseOrderPage}
                      </div>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          setCurrentCloseOrderPage((prevPage) => prevPage + 1)
                        }
                        disabled={
                          currentCloseOrderPage === closeOrderTotalPages
                        }
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
}

function OrderRow(props) {
  const [popup, setpopup] = useState(false);
  const [spin, setspin] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.AuthReducer);
  let progress_width = 0;
  let back = "rgb(16 129 53 / 10%)";
  const [dis, setdis] = useState(false);
  if (props["total_buy"] === undefined) {
    progress_width = (props.total_executed * 100) / props.volume;
    back = props.type === "buy" ? "rgba(35, 172, 80, 0.4)" : "#81101026";
  } else {
    progress_width = (props.total_executed * 100) / props.volume;
    back = "rgb(16 129 53 / 10%)";
  }

  const deleteOrder = (order_id, user_id) => {
    const token = user?.params ? user.params.token : user.token;
    setspin("spinner-border spinner-border-sm");
    N_cancleOrderById(token, order_id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getUserOrder(token, props.type));
          dispatch(getUserBalance(token));
          setTimeout(() => {
            setspin("");
            setpopup(false);
            setdis(false);
          }, 1000);
          // setLoading(false);
          NotificationManager.success(res.message);
        } else {
          NotificationManager.error(res.message);
        }
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  // function getDateTime(props) {
  //   let ddate = props.order_date;
  //   if (ddate === 0) {
  //     ddate = props.execution_date;
  //   }
  //   return ddate;
  // }

  return (
    <>
      {popup ? (
        <>
          <div
            style={{
              position: "absolute",
              height: "43%",
              width: "99%",
              display: "flex",
              flexDirection: "column",
              zIndex: 200,
              top: "45px",
              left: "2px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "100%",
                zIndex: 1000,
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
              }}
            >
              <div className="row" style={{ width: "220px" }}>
                <div className="shadow-sm p-0">
                  <div className=" card-body bg-white text-center">
                    <div>
                      <h6 className="text-red fs-12">Want to Delete Order ?</h6>
                      <div className="mt-3">
                        <button
                          type="button"
                          className="btn btn-light btn-sm me-2"
                          onClick={(e) => {
                            if (!dis) {
                              setpopup(false);
                            }
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            if (!dis) {
                              setdis(true);
                              deleteOrder(
                                props.order_id,
                                props.user_id,
                                props["total_buy"] === undefined
                                  ? "sell"
                                  : "buy"
                              );
                            }
                          }}
                        >
                          {dis ? (
                            <span
                              className={`${spin} mx-2`}
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : null}
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <tr className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
        <td>{props?.currency_type?.toUpperCase()}</td>
        <td>{round(props?.volume)}</td>
        <td>{(props?.raw_price).toFixed(2)}</td>
        <td>{(props?.volume * props?.raw_price).toFixed(2)}</td>
        <td>
          {Math.round(
            props.type === "buy" ? props.total_executed : props.total_executed
          )}
        </td>
        <td>
          {props?.order_type !== 1 ? (
            <div className="">
              <span className="text-muted">
                {new Date(Number(props.timestamp)).toLocaleString()}
              </span>
            </div>
          ) : (
            <div className="">
              {new Date(Number(props.timestamp)).toLocaleString()}
            </div>
          )}
        </td>
        <td>
          <button
            className="btn btn-danger"
            onClick={() => {
              setpopup(true);
            }}
          >
            Delete
          </button>
        </td>
      </tr>
    </>
  );
}

function OrderRowCompleted(props) {
  const [popup, setpopup] = useState(false);
  const [spin, setspin] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.AuthReducer);
  let progress_width = 0;
  let back = "rgb(16 129 53 / 10%)";
  const [dis, setdis] = useState(false);
  if (props["total_buy"] === undefined) {
    progress_width = (props.total_executed * 100) / props.volume;
    back = props.type === "buy" ? "rgba(35, 172, 80, 0.4)" : "#81101026";
  } else {
    progress_width = (props.total_executed * 100) / props.volume;
    back = "rgb(16 129 53 / 10%)";
  }

  const deleteOrder = (order_id, user_id) => {
    const token = user?.params ? user.params.token : user.token;
    setspin("spinner-border spinner-border-sm");
    N_cancleOrderById(token, order_id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getUserOrder(token, props.type));
          dispatch(getUserBalance(token));
          setTimeout(() => {
            setspin("");
            setpopup(false);
            setdis(false);
          }, 1000);
          // setLoading(false);
          NotificationManager.success(res.message);
        } else {
          NotificationManager.error(res.message);
        }
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  // function getDateTime(props) {
  //   let ddate = props.order_date;
  //   if (ddate === 0) {
  //     ddate = props.execution_date;
  //   }
  //   return ddate;
  // }

  return (
    <>
      {popup ? (
        <>
          <div
            style={{
              position: "absolute",
              height: "43%",
              width: "99%",
              display: "flex",
              flexDirection: "column",
              zIndex: 200,
              top: "45px",
              left: "2px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "100%",
                zIndex: 1000,
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
              }}
            >
              <div className="row" style={{ width: "220px" }}>
                <div className="shadow-sm p-0">
                  <div className=" card-body bg-white text-center">
                    <div>
                      <h6 className="text-red fs-12">Want to Delete Order ?</h6>
                      <div className="mt-3">
                        <button
                          type="button"
                          className="btn btn-light btn-sm me-2"
                          onClick={(e) => {
                            if (!dis) {
                              setpopup(false);
                            }
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            if (!dis) {
                              setdis(true);
                              deleteOrder(
                                props.order_id,
                                props.user_id,
                                props["total_buy"] === undefined
                                  ? "sell"
                                  : "buy"
                              );
                            }
                          }}
                        >
                          {dis ? (
                            <span
                              className={`${spin} mx-2`}
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : null}
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <tr className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
        <td>{props?.currency_type?.toUpperCase()}</td>
        <td>{round(props?.volume)}</td>
        <td>{(props?.raw_price).toFixed(2)}</td>
        <td>{(props?.volume * props?.raw_price).toFixed(2)}</td>
        <td>
          {Math.round(
            props.type === "buy" ? props.total_executed : props.total_executed
          )}
        </td>
        <td>
          {props?.order_type !== 1 ? (
            <div className="">
              <span className="text-muted">
                {new Date(Number(props.timestamp)).toLocaleString()}
              </span>
            </div>
          ) : (
            <div className="">
              {new Date(Number(props.timestamp)).toLocaleString()}
            </div>
          )}
        </td>
        <td>
          {props?.order_type !== 1 ? (
            <div
              title="Cancel Order"
              className=""
              onClick={() => {
                setpopup(true);
              }}
            >
              <i className="fa fa-trash text-danger"></i>
            </div>
          ) : props?.status == "c" ? (
            <span className="">Executed</span>
          ) : (
            <span className="">Cancel</span>
          )}
        </td>
      </tr>
    </>
  );
}
