import React from "react";
import { NavLink } from "react-router-dom";
import "./sidebar.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function ProfileSidebar(props) {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  if (!isLoggedIn) props.history.replace("/login");
  return (
    <>
      <div className="modified_collapse sidenav_toggle">
        <button
          class="btn text-white border-0 text-left d-block d-lg-none sidebar_toggle collapsed"
          data-bs-toggle="collapse"
          href="#collapseExample"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          Sidebar
        </button>

        <div class="sidebar collapse" id="collapseExample">
          <NavLink to="/profile">
            <i className="fa fa-user me-2 ms-3 mt-1" /> Profile
          </NavLink>

          <NavLink to="/currency_preference">
            <i className="fa fa-usd me-2 ms-3 mt-1" /> Currency preference
          </NavLink>

          <NavLink to="/referral">
            <i className="fa fa-tree me-2 ms-3 mt-1" /> Referral
          </NavLink>

          <NavLink to="/user_kyc">
            <i className="fa fa-book me-2 ms-3 mt-1" /> Verify KYC
          </NavLink>

          <NavLink to="/payment_option">
            <i className="fa fa-usd me-2 ms-3 mt-1" /> Payment options
          </NavLink>

          <NavLink to="/notification">
            <i className="fa fa-bell me-2 ms-3 mt-1" /> Notification preferences
          </NavLink>

          <NavLink to="/fees">
            <i className="fa fa-inr me-2 ms-3 mt-1" /> Fees
          </NavLink>

          <NavLink to="/2fa">
            <i className="fa fa-shield me-2 ms-3 mt-1" /> Two Factor
            Authentication
          </NavLink>

          <NavLink to="/activity_log">
            <i className="fa fa-info me-2 ms-3 mt-1" /> Activity Logs
          </NavLink>

          <NavLink to="/download_report">
            <i className="fa fa-file me-2 ms-3 mt-1 fs-12" /> Download trading
            report
          </NavLink>

          <NavLink to="/privacy_control">
            <i className="fa fa-shield me-2 ms-3 mt-1" /> Privacy Control
          </NavLink>

          <NavLink to="/coupan">
            <i className="fa fa-gift me-2 ms-3 mt-1" /> Coupon Rewards
          </NavLink>

          <NavLink to="/upcoming_program">
            <i className="fa fa-lock me-2 ms-3 mt-1" /> Unlock Programs
          </NavLink>
        </div>
      </div>
    </>
  );
}
