import React from "react";
import "./comp1.css";

const Body = () => {
  return (
    <div id="div-docs" className="page-wrapper-docs">
      <div id="div-docs" className="dark-box-docs"></div>
      <div id="div-docs" class="content-docs">
        <h1 id="public-rest-api-for-CTSKOLA">Public Rest API for CTSKOLA</h1>
        <h2 id="introduction">Introduction</h2>
        <p>
          This document details the use of CTSKOLA’s REST API for spot exchange.
          This helps you automate trades in real-time, stream live crypto rates
          and build other integrations for your trading journey.
        </p>

        <p>
          Our REST API is organized into publicly accessible endpoints (market
          data, exchange status, etc.), and private authenticated endpoints
          (trading, funding, user data) which require requests to be signed.
        </p>
        <h2 id="api-key-setup">API Key Setup</h2>
        <ul id="ul-docs">
          <li>
            Some endpoints will require an API Key. Please refer to{" "}
            <a id="ancor" href="https://ctskola.io/">
              this page
            </a>{" "}
            regarding API key creation.
          </li>
          <li>
            Once API key is created, it is recommended to set IP restrictions on
            the key for security reasons.
          </li>
          <li>
            <strong>Never share your API key/secret key to ANYONE.</strong>
            If the API keys were accidentally shared, please delete them
            immediately and create a new key.
          </li>
        </ul>
        <h2 id="api-key-restrictions">API Key Restrictions</h2>
        <ul id="ul-docs">
          <li>
            The default settings of the API key enable you to a Read-Only key.
            You can enable trading rights by explicity enabling SPOT Trade
            option under permissions for a key
          </li>
          <li>You can create 5 api keys for an account</li>
        </ul>
        <h3 id="spot-account">Spot Account</h3>
        <p>
          A <code>SPOT</code> account is provided by default upon creation of a
          CTSKOLA Account.
        </p>
        <h2 id="contact-us">Contact Us</h2>
        <ul id="ul-docs">
          <li>
            CTSKOLA Email
            <ul id="ul-docs">
              <li>Please email us at api@ctskola.io</li>
            </ul>
          </li>
          <li>
            <a id="ancor" href="">
              CTSKOLA API Telegram Group
            </a>

            <ul id="ul-docs">
              <li>
                For any general questions about the API not covered in the
                documentation.
              </li>
            </ul>
          </li>
        </ul>
        <h2 id="general-api-information">General API Information</h2>
        <ul id="ul-docs">
          <li>
            The base endpoint is: <strong>https://order.ctskola.io</strong>
          </li>
          <li>All endpoints return either a JSON object or array.</li>
          <li>
            All time and timestamp related fields are in{" "}
            <strong>milliseconds</strong>.
          </li>
        </ul>
        <h2 id="http-return-codes">HTTP Return Codes</h2>
        <ul id="ul-docs">
          <li>
            HTTP <code>4XX</code> return codes are used for malformed requests;
            the issue is on the sender's side.
          </li>
          <li>
            HTTP <code>403</code> return code is used when the WAF Limit (Web
            Application Firewall) has been violated.
          </li>
          <li>
            HTTP <code>429</code> return code is used when breaking a request
            rate limit.
          </li>
          <li>
            HTTP <code>418</code> return code is used when an IP has been
            auto-banned for continuing to send requests after receiving{" "}
            <code>429</code> codes.
          </li>
          <li>
            HTTP <code>5XX</code> return codes are used for internal errors; the
            issue is on CTSKOLA's side. It is important to <strong>NOT</strong>{" "}
            treat this as a failure operation; the execution status is
            <strong>UNKNOWN</strong> and could have been a success.
          </li>
        </ul>
        <h2 id="error-codes-and-messages">Error Codes and Messages</h2>
        <blockquote>
          <p>Sample Payload below:</p>
        </blockquote>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight json-doc tab-json-doc">
            <code>
              <span id="sp" class="p">
                {"{"}
              </span>
              <span id="sp" class="w"></span>
              <span id="sp" class="nl">
                "code"
              </span>
              <span id="sp" class="p">
                :
              </span>
              <span id="sp" class="w">
                {" "}
              </span>
              <span id="sp" class="mi">
                400
              </span>
              <span id="sp" class="p">
                ,
              </span>
              <span id="sp" class="w"></span>
              <span id="sp" class="nl">
                "message"
              </span>
              <span id="sp" class="p">
                :
              </span>
              <span id="sp" class="w">
                {" "}
              </span>
              <span id="sp" class="s2">
                "Invalid symbol."
              </span>
              <span id="sp" class="w"></span>
              <span id="sp" class="p">
                {"}"}
              </span>
              <span id="sp" class="w"></span>
            </code>
          </pre>
        </div>
        <ul id="ul-docs">
          <li>
            If there is an error, the API will return an error with a message of
            the reason.
          </li>
          <li>Specific error codes and messages defined in Error Codes.</li>
          <li>Any endpoint can return an ERROR</li>
        </ul>
        <h2 id="general-information-on-endpoints">
          General Information on Endpoints
        </h2>
        <ul id="ul-docs">
          <li>
            For <code>GET</code> endpoints, parameters must be sent as a{" "}
            <code>query string</code>.
          </li>
          <li>
            For <code>POST</code>, <code>PUT</code>, and <code>DELETE</code>{" "}
            endpoints, the parameters must be sent as a<code>request body</code>{" "}
            with content type <code>application/x-www-form-urlencoded</code>.
          </li>
          <li>Parameters may be sent in any order.</li>
          <li>
            If a parameter sent in both the <code>query string</code> and{" "}
            <code>request body</code>, the <code>query string</code> parameter
            will be used.
          </li>
        </ul>
        <h2 id="limits">Limits</h2>
        <h3 id="general-info-on-limits">General Info on Limits</h3>
        <ul id="ul-docs">
          <li>
            Limits are set on specific api endpoints. These will be mentioned in
            the description of the endpoint. For e.g the Ping api will have a
            limit of 1 request/sec while Place order api will have a limit of 10
            requests/sec
          </li>
          <li>A 429 will be returned when rate limit is violated.</li>
          <li>
            <strong>The limits on the API are based on the API keys.</strong>
          </li>
          <li>
            We recommend using the websocket for getting data as much as
            possible, as this will not count to the request rate limit.
          </li>
        </ul>
        <h3 id="ip-limits">IP Limits</h3>
        <ul id="ul-docs">
          <li>
            When a 429 is received, it's your obligation as an API to back off
            and not spam the API.
          </li>
          <li>
            <strong>
              Repeatedly violating rate limits and/or failing to back off after
              receiving 429s will result in an automated IP ban (HTTP status
              418).
            </strong>
          </li>
          <li>
            IP bans are tracked and <strong>scale in duration</strong> for
            repeat offenders, <strong>from 2 minutes to 3 days</strong>.
          </li>
          <li>
            A <code>Retry-After</code> header is sent with a 418 or 429
            responses and will give the <strong>number of seconds</strong>{" "}
            required to wait, in the case of a 429, to prevent a ban, or, in the
            case of a 418, until the ban is over.
          </li>
        </ul>
        <h3 id="websocket-limits">Websocket Limits</h3>
        <ul id="ul-docs">
          <li>
            WebSocket connections have a limit of 5 incoming messages per
            second. A message is considered:
            <ul id="ul-docs">
              <li>A PING frame</li>
              <li>A PONG frame</li>
              <li>A JSON controlled message (e.g. subscribe, unsubscribe)</li>
            </ul>
          </li>
          <li>
            A connection that goes beyond the limit will be disconnected; IPs
            that are repeatedly disconnected may be banned.
          </li>
          <li>A single connection can listen to a maximum of 1024 streams.</li>
        </ul>
        <h1 id="public-api-endpoints">Public API Endpoints</h1>
        <p>
          <strong>Order status (status):</strong>
        </p>

        <ul id="ul-docs">
          <li>idle - The order is idle not yet triggered.</li>
          <li>
            wait - The order is still open and waiting to be filled completely.
          </li>
          <li>done - The order has been completely filled.</li>
          <li>cancel - The order has been canceled by the user.</li>
        </ul>

        <p>
          <strong>Order types (orderTypes, type):</strong>
        </p>

        <ul id="ul-docs">
          <li>limit</li>
          <li>stop_limit</li>
        </ul>

        <p>
          <strong>Order side (side):</strong>
        </p>

        <ul id="ul-docs">
          <li>buy</li>
          <li>sell</li>
        </ul>
        <h1>Ideal API Endpoint</h1>
        <h2>
          {"["}Section A {"]"} Spot Exchanges
        </h2>
        <h2 id="summary">Summary</h2>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight plaintext">
            <code>GET /api/get-volume-data</code>
          </pre>
        </div>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight shell tab-shell">
            <code>
              curl{" "}
              <span id="sp" class="nt">
                --location
              </span>{" "}
              <span id="sp" class="nt">
                --request
              </span>{" "}
              GET{" "}
              <span id="sp" class="s1">
                'https://order.ctskola.io/api/get-volume-data?currency_type=RBC&amp;compare_currency=INR'
              </span>
            </code>
          </pre>
        </div>
        <blockquote>
          <p>Response:</p>
        </blockquote>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight json-doc tab-json-doc">
            <code>
              <span id="sp" class="p">
                <span id="sp" class="p">
                  {"{"}
                </span>
                <span id="sp" class="w"></span>
                <span id="sp" class="nl">
                  "trading_pairs"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "RBC_INR"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "high"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  450
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "low"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  400
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "volume"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  4000
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "last_price"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  435
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "price_change_percent_24h"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  -1.3
                </span>
                <span id="sp" class="p">
                  {"}"}
                </span>
                <span id="sp" class="p">
                  ,
                </span>
              </span>
            </code>
          </pre>
        </div>
        <p>
          The summary endpoint is to provide an overview of market data for all
          tickers and all market pairs on the exchange
        </p>

        <p>
          <strong>Query Parameters:</strong>
        </p>

        <table id="table-docs">
          <thead>
            <tr id="tr-docs">
              <th>Currency</th>
              <th>Compare Currency</th>
            </tr>
          </thead>
          <tbody id="tbody-docs">
            <tr id="tr-docs">
              <td>currency_type</td>
              <td>STRING</td>
            </tr>
            <tr id="tr-docs">
              <td>compare_currency</td>
              <td>STRING</td>
            </tr>
          </tbody>
        </table>

        <h1>ENDPOINT A1</h1>
        <h2 id="test-connectivity">ASSETS /assets</h2>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight plaintext">
            <code>GET /api/get-currency-data</code>
          </pre>
        </div>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight shell tab-shell">
            <code>
              curl{" "}
              <span id="sp" class="nt">
                --location
              </span>{" "}
              <span id="sp" class="nt">
                --request
              </span>{" "}
              GET{" "}
              <span id="sp" class="s1">
                'https://order.ctskola.io/api/get-currency-data'
              </span>
            </code>
          </pre>
        </div>
        <blockquote>
          <p>Response:</p>
        </blockquote>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight json-doc tab-json-doc">
            <code>
              <span id="sp" class="p">
                <span id="sp" class="p">
                  {"{"}
                </span>
                <span id="sp" class="nl">
                  "BTC"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="p">
                  {"{"}
                </span>
                <span id="sp" class="w"></span>
                <span id="sp" class="nl">
                  "name"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "bitcoin"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "unified_cryptoasset_id"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "1"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  "can_withdraw"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "true"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "can_deposit"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "true"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "min_withdraw"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "0.01"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "max_withdraw "
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "100"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  "symbol"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "BTC"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "maker_fee"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "0.01"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "taker_fee"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "0.01"
                </span>
                <span id="sp" class="p">
                  {"}"}
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  "ETH"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="p">
                  {"{"}
                </span>
                <span id="sp" class="w"></span>
                <span id="sp" class="nl">
                  "name"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "ethereum"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "unified_cryptoasset_id"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "1027"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  "can_withdraw"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "true"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "can_deposit"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "true"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "min_withdraw"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "10"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "max_withdraw "
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "10000"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  "symbol"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "ETH"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "maker_fee"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "0.01"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "taker_fee"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "0.01"
                </span>
                <span id="sp" class="p">
                  {"}"}
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  "TRX"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="p">
                  {"{"}
                </span>
                <span id="sp" class="w"></span>
                <span id="sp" class="nl">
                  "name"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "tron"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "unified_cryptoasset_id"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "1958"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  "can_withdraw"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "true"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "can_deposit"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "true"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "min_withdraw"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "10"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "max_withdraw "
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "100000"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  "symbol"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "TRX"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "maker_fee"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "0.01"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "taker_fee"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "0.01"
                </span>
                <span id="sp" class="p">
                  {"}"}
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  "BNB"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="p">
                  {"{"}
                </span>
                <span id="sp" class="w"></span>
                <span id="sp" class="nl">
                  "name"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "binance-coin"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "unified_cryptoasset_id"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "1839"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  "can_withdraw"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "true"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "can_deposit"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "true"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "min_withdraw"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "0.01"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "max_withdraw "
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "100"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  "symbol"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "BNB"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "maker_fee"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "0.01"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "taker_fee"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "0.01"
                </span>
                <span id="sp" class="p">
                  {"}"}
                </span>
                <span id="sp" class="p">
                  {"}"}
                </span>
              </span>
              <span id="sp" class="w"></span>
            </code>
          </pre>
        </div>
        <p>
          The assets endpoint is to provide a detailed summary for each currency
          available on the exchange.
        </p>

        <h1>ENDPOINT A2</h1>
        <h2 id="test-connectivity">TICKER /ticker</h2>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight plaintext">
            <code>GET /api/get-trade-data</code>
          </pre>
        </div>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight shell tab-shell">
            <code>
              curl{" "}
              <span id="sp" class="nt">
                --location
              </span>{" "}
              <span id="sp" class="nt">
                --request
              </span>{" "}
              GET{" "}
              <span id="sp" class="s1">
                'https://order.ctskola.io/api/get-trade-data'
              </span>
            </code>
          </pre>
        </div>
        <blockquote>
          <p>Response:</p>
        </blockquote>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight json-doc tab-json-doc">
            <code>
              <span id="sp" class="p">
                <span id="sp" class="p">
                  {"["}
                </span>
                <span id="sp" class="p">
                  {"{"}
                </span>
                <span id="sp" class="w"></span>
                <span id="sp" class="nl">
                  "history_id"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "history$1826d9d4ca9"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "price"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "5.545397"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "volume"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "200"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "currency_type"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "trx"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "compare_currency"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "inr"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "trade_date"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "1659696401577"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "type"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "buy"
                </span>
                <span id="sp" class="p">
                  {"}"}
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="p">
                  {"{"}
                </span>
                <span id="sp" class="w"></span>
                <span id="sp" class="nl">
                  "history_id"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "history$182817afbcb"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "price"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "29.753157"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "volume"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "10"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "currency_type"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "xrp"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "compare_currency"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "inr"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "trade_date"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "1660029696971"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "type"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "sell"
                </span>
                <span id="sp" class="p">
                  {"}"}
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="p">
                  {"{"}
                </span>
                <span id="sp" class="w"></span>
                <span id="sp" class="nl">
                  "history_id"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "history$1826d960a22"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "price"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "79.39871"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "volume"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "100"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "currency_type"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "usdt"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "compare_currency"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "inr"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "trade_date"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "1659695925794"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "type"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "sell"
                </span>
                <span id="sp" class="p">
                  {"}"}
                </span>
                <span id="sp" class="p">
                  {"]"}
                </span>
              </span>
              <span id="sp" class="w"></span>
            </code>
          </pre>
        </div>
        <p>
          The ticker endpoint is to provide a 24-hour pricing and volume summary
          for each market pair available on the exchange
        </p>

        <h1>ENDPOINT A3</h1>
        <h2 id="test-connectivity">ORDERBOOK /orderbook/market_pair</h2>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight plaintext">
            <code>GET /api/get-order-data</code>
          </pre>
        </div>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight shell tab-shell">
            <code>
              curl{" "}
              <span id="sp" class="nt">
                --location
              </span>{" "}
              <span id="sp" class="nt">
                --request
              </span>{" "}
              GET{" "}
              <span id="sp" class="s1">
                'https://order.ctskola.io/api/get-order-data?currency_type=usdt&amp;compare_currency=inr'
              </span>
            </code>
          </pre>
        </div>
        <blockquote>
          <p>Response:</p>
        </blockquote>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight json-doc tab-json-doc">
            <code>
              <span id="sp" class="p">
                <span id="sp" class="p">
                  {"{"}
                </span>
                <br />
                <span id="sp" class="nl">
                  "order_date"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="nl">
                  "1660989543740"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <br />
                <span id="sp" class="nl">
                  "buy"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="p">
                  {"["}
                </span>
                <br />
                <span id="sp" class="p">
                  {"["}
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "200"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "79.477354"
                </span>
                <span id="sp" class="p">
                  {"]"}
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <br />
                <span id="sp" class="p">
                  {"["}
                </span>
                <span id="sp" class="nl">
                  "100"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "79.504074"
                </span>
                <span id="sp" class="p">
                  {"]"}
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <br />
                <span id="sp" class="p">
                  {"]"}
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <br />
                <span id="sp" class="nl">
                  "sell"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="p">
                  {"["}
                </span>
                <br />
                <span id="sp" class="p">
                  {"["}
                </span>
                <span id="sp" class="nl">
                  "21"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "79.421589"
                </span>
                <span id="sp" class="p">
                  {"]"}
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <br />
                <span id="sp" class="p">
                  {"["}
                </span>
                <span id="sp" class="nl">
                  "5"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  "79.207332"
                </span>
                <span id="sp" class="p">
                  {"]"}
                </span>
                <br />
                <span id="sp" class="p">
                  {"]"}
                </span>
                <br />
                <span id="sp" class="p">
                  {"}"}
                </span>
              </span>
              <span id="sp" class="w"></span>
            </code>
          </pre>
        </div>
        <p>
          The order book endpoint is to provide a complete level 2 order book{" "}
          {"("}arranged by best asks/bids{")"} with full depth returned for a
          given market pair.
        </p>
        <p>
          <strong>Query Parameters:</strong>
        </p>

        <table id="table-docs">
          <thead>
            <tr id="tr-docs">
              <th>Currency</th>
              <th>Compare Currency</th>
            </tr>
          </thead>
          <tbody id="tbody-docs">
            <tr id="tr-docs">
              <td>currency_type</td>
              <td>STRING</td>
            </tr>
            <tr id="tr-docs">
              <td>compare_currency</td>
              <td>String</td>
            </tr>
          </tbody>
        </table>
        <h1>ENDPOINT A4</h1>
        <h2 id="trades">TRADES /trades/market_pair</h2>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight plaintext">
            <code>GET /api/get-trade-data</code>
          </pre>
        </div>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight shell tab-shell">
            <code>
              curl{" "}
              <span id="sp" class="nt">
                --location
              </span>{" "}
              <span id="sp" class="nt">
                --request
              </span>{" "}
              GET{" "}
              <span id="sp" class="s1">
                'https://order.ctskola.io/api/get-trade-data?currency_type=RBC&amp;compare_currency=INR'
              </span>
            </code>
          </pre>
        </div>
        <blockquote>
          <p>Response:</p>
        </blockquote>
        <div id="div-docs" class="highlight">
          <pre id="pre-docs" class="highlight json-doc tab-json-doc">
            <code>
              <span id="sp" class="p">
                <span id="sp" class="p">
                  {"["}
                </span>
                <span id="sp" class="p">
                  {"{"}
                </span>
                <span id="sp" class="w"></span>
                <span id="sp" class="nl">
                  "history_id"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "history$1826d9d4ca9"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "price"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "400"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "volume"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  "200"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "currency_type"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "rbc"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "compare_currency"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "inr"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "trade_date"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "1659696401577"
                </span>
                <span id="sp" class="p">
                  ,
                </span>
                <span id="sp" class="nl">
                  {" "}
                  "type"
                </span>
                <span id="sp" class="p">
                  :
                </span>
                <span id="sp" class="w">
                  {" "}
                </span>
                <span id="sp" class="s2">
                  {" "}
                  "buy"
                </span>
                <span id="sp" class="p">
                  {"}"}
                </span>
                <span id="sp" class="p">
                  {"]"}
                </span>
              </span>
              <span id="sp" class="w"></span>
            </code>
          </pre>
        </div>
        <p>
          The trades endpoint is to return data on all recently completed trades
          for a given market pair.
        </p>

        <p>
          <strong>Query Parameters:</strong>
        </p>

        <table id="table-docs">
          <thead>
            <tr id="tr-docs">
              <th>Currency</th>
              <th>Compare Currency</th>
            </tr>
          </thead>
          <tbody id="tbody-docs">
            <tr id="tr-docs">
              <td>currency_type</td>
              <td>STRING</td>
            </tr>
            <tr id="tr-docs">
              <td>compare_currency</td>
              <td>String</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Body;
