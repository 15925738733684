import React from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Banner(props) {
  AOS.init({ duration: 1000 });
  const { coins } = useSelector((state) => state.coinDBReducer);
  let coins1 = Object.values(coins);

  const banner_child =
    coins1 &&
    coins1.map((item, index) => {
      let usdtprize = coins1 && coins1.find((item) => item.symbol == "INR");
      return (
        <>
          {item.symbol == "BTC" ||
          item.symbol == "BNB" ||
          item.symbol == "ETH" ||
          item.symbol == "TRX" ||
          item.symbol == "RBC" ? (
            <div className="" key={index}>
              <div
                className="banner_child"
               
              >
                <div className="card-body">
                  <p className="">
                    {item.symbol}/INR&nbsp;&nbsp;
                    <span
                      className={
                        item.direction_inr == "up"
                          ? "text-green"
                          : "text-red"
                      }
                    >
                      {item.price_change_percentage_1h_inr}%
                    </span>
                  </p>
                  <h5>
                  ₹
                    {(
                      item.raw_current_price_inr
                  
                    ).toFixed(8)}
                  </h5>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    });
  return (
    <>
      <div className="banner-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-8 mb-3">
              <div className="banner_left_inner">
                <h2 className="bannertxt">
                  Buy & sell Crypto on{" "}
                  <span className="text-warning">CTSKOLA</span>
                </h2>
                <h3 className="">
                  {" "}
                  The Highly Efficient exchange for stable and secure Trade.
                </h3>
                <p>
                  Trade Bitcoin, Ethereum, BAT and 150+ cryptos in seconds, on
                  our simple and ultra-secure crypto exchange
                </p>
                <a className="btn btn-warning btn-lg" href="/login">
                  Start Trading Now
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-4 mb-3"  data-aos="fade-up">
              <img className="bannerImg" src="../img/trading-img.png" alt="" />
            </div>
          </div>
        </div>       
      </div>

      <div className="container currency_section">
          <div className="display_row">
            {banner_child}
          </div>
        </div>
    </>
  );
}
