import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import { submitKyc } from "./redux/actions/authActions";

import FullLoader from "./components/FullLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  N_checkKYCStatus,
  N_getCity,
  N_userEditSubmit,
  N_getState,
  N_getUserProfile,
  N_sendOTP,
  N_verifyUserOTP,
  N_sendOTPMobile,
} from "./redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import { SEND_OTP } from "./redux/constant";
import { Link } from "react-router-dom";

export default function EditEmail(props) {
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState();
  const [updateloading, setUpdateLoading] = useState();
  const [getUser, setUser] = useState();
  const [new_email, setNewEmail] = useState();
  const [email_otp, setEmailOTP] = useState();

  const [otp, setOtp] = useState();
  const [action, setAction] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [isverified, setVerified] = useState(false);
  const [disableSend, setDisableSend] = useState(false);
  const [disable, setDisable] = useState(true);
  const [emaildisable, setEmailDisable] = useState(true);
  const [fullloading, setFullLoader] = useState(true);
  const [otploader, setOtpLoader] = useState(false);
  const [isOtpSend, setOtpSend] = useState(false);
  const [ctime, setctime] = useState("01:00");

  let token = user?.params ? user.params.token : user.token;
  useEffect(() => {
    N_getUserProfile(token).then((res) => {
      if (res?.params?.profile_info) {
        setFullLoader(false);
        setUser(res.params.profile_info);
      }
    });
  }, []);

  const sendOtp = (val, action) => {
    let data = {};
    if (action == "email") {
      data = {
        email: val,
      };
    }
    if (action == "mobile") {
      data = {
        mobile_no: val,
      };
    }
    N_sendOTP(data, token).then((res) => {
      console.log(res);
      if (res.status == 200) {
        NotificationManager.success(res.message);
        setDisable(false);
      } else {
        NotificationManager.error(res.message);
      }
    });
  };
  const verfiyOtp = () => {
    const token = user?.params ? user.params.token : user.token;
    const body = {
      otp: otp,
      action: action,
    };
    setLoading(true);
    N_verifyUserOTP(body, token).then((res) => {
      if (res.status === 200) {
        setActiveTab(1);
        setLoading(false);
        NotificationManager.success(res.message);
        setVerified(true);
      } else {
        NotificationManager.error(res.message);
      }
    });
  };

  const otpCountdown = () => {
    let duration = 60;
    // const display = document.getElementById("#timer");
    let timer = duration,
      minutes,
      seconds;
    const tint = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setctime(minutes + ":" + seconds);

      if (--timer < 0) {
        // timer = duration;
        clearInterval(tint);
        setOtpSend(false);
      }
    }, 1000);
  };
  if (fullloading)
    return (
      <>
        <Header {...props} />
        <FullLoader />
      </>
    );
  else
    return (
      <>
        <Header {...props} />
        <div className="col-lg-12">
          {/* <div className="modified_collapse bg-secondary"> */}
          {/* <button
              className="btn text-white border-0 text-left d-block d-lg-none mt-5 sidebar_toggle collapsed"
              data-bs-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Profile
            </button> */}
          <ProfileSidebar />
          {/* </div> */}
          <div className="main-content">
            {/* <div className="mt-3">
              <h5 className="mb-3">Edit Personal Info</h5>
            </div> */}
            <div className="row mt-3">
              <div className="col-lg-12 m-auto">
                <div className="profile-card">
                  <div className="image">
                    <img
                      src="/img/account_profile.png"
                      style={{ width: "130px", height: "130px" }}
                      className="profile-img"
                    />
                  </div>

                  <div className="col-lg-4 col-12 m-auto">
                    <form method="post" id="welcome_form">
                      {!activeTab ? (
                        <div id="personal_info" className="">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="user"
                              id="user_email"
                              onClick={(e) => {
                                sendOtp(e.target.value, "email");
                                setAction("email");
                              }}
                              value={
                                getUser && getUser.email ? getUser.email : ""
                              }
                            />
                            <label
                              className="form-check-label"
                              for="user_email"
                            >
                              Email
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="user"
                              id="user_mobile"
                              onClick={(e) => {
                                sendOtp(e.target.value, "mobile");
                                setAction("mobile");
                              }}
                              value={
                                getUser && getUser.mobile_number
                                  ? getUser.mobile_number
                                  : ""
                              }
                            />
                            <label
                              className="form-check-label"
                              for="user_mobile"
                            >
                              Mobile
                            </label>
                          </div>

                          {/* <div className="form-group">
                          <label htmlFor="user_email">Email ID</label>
                          <input
                            type="text"
                            name="user_email"
                            defaultValue={
                              getUser && getUser.email ? getUser.email : ""
                            }
                            className="form-control"
                            id="user_email"
                          />
                        </div> */}

                          <div className="form-group">
                            <label htmlFor="user_otp">OTP</label>
                            <input
                              type="number"
                              name="user_otp"
                              disabled={disable}
                              value={otp}
                              onChange={(e) => {
                                setOtp(e.target.value);
                              }}
                              className="form-control"
                              id="user_otp"
                            />
                          </div>

                          <div className="d-grid">
                            <button
                              type="button"
                              onClick={() => {
                                verfiyOtp();
                              }}
                              className="btn btn-primary"
                            >
                              {loading ? (
                                <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                              ) : null}
                              <span id="reg">Verify</span>
                            </button>
                          </div>
                        </div>
                      ) : null}
                      {activeTab ? (
                        <div id="email">
                          <div className={`form-group`}>
                            <small id="msg" style={{ fontSize: "15px" }}>
                              Error Message
                            </small>
                          </div>
                          <div className="form-group">
                            <label htmlFor="user_email" className="text-red">
                              {getUser && getUser.email ? getUser.email : ""}
                            </label>
                            <div className="input-group">
                              <input
                                type="email"
                                className="form-control"
                                id="user_email"
                                value={new_email}
                                onChange={(e) => setNewEmail(e.target.value)}
                                placeholder="Enter your new Email"
                              />
                              <span className="input-group-text bg-secondary text-white">
                                {!isOtpSend ? (
                                  <button
                                    className="border-0 bg-transparent text-white"
                                    type="button"
                                    disabled={disableSend}
                                    onClick={() => {
                                      if (new_email) {
                                        setOtpLoader(true);
                                        let dt = {
                                          email: new_email,
                                        };
                                        N_sendOTP(
                                          dt,
                                          user?.params
                                            ? user.params.token
                                            : user.token
                                        ).then((d) => {
                                          if (d.status == 200) {
                                            NotificationManager.success(
                                              d.message
                                            );
                                            setEmailDisable(false);
                                            setOtpSend(true);
                                            otpCountdown();
                                          } else {
                                            NotificationManager.error(
                                              d.message
                                            );
                                          }
                                          setOtpLoader(false);
                                        });
                                      } else {
                                        NotificationManager.error(
                                          "Please Enter valid Email Address"
                                        );
                                      }
                                    }}
                                    id="button-addon2"
                                  >
                                    {otploader ? (
                                      <span className="loading-icon fas fa-spinner fa-spin me-2"></span>
                                    ) : (
                                      "Send"
                                    )}
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    id="button-addon2"
                                    className="btn btn-outline-info btn-outline-light text-info"
                                  >
                                    {isOtpSend ? ctime : null}
                                  </button>
                                )}
                              </span>
                            </div>
                          </div>

                          <div className={`form-group g_color`}>
                            <label htmlFor="user_otp">OTP</label>
                            <input
                              type="text"
                              name="user_otp"
                              id="otp"
                              className="form-control"
                              maxLength={6}
                              disabled={emaildisable}
                              value={email_otp}
                              onChange={(e) =>
                                setEmailOTP(
                                  e.target.value
                                    .replace(/[^0-9.]/g, "")
                                    .replace(/(\..*?)\..*/g, "$1")
                                )
                              }
                              required=""
                              placeholder="Enter 6 digit otp"
                            />
                            <i className="fas fa-check-circle"></i>
                            <i className="fas fa-exclamation-circle"></i>
                            <small></small>
                          </div>
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                setUpdateLoading(true);
                                const dtt = {
                                  otp: email_otp,
                                  action: "email",
                                  type: "update",
                                  email: new_email,
                                };
                                N_verifyUserOTP(
                                  dtt,
                                  user?.params ? user.params.token : user.token
                                ).then((res) => {
                                  setUpdateLoading(false);
                                  if (res.status == 200) {
                                    NotificationManager.success(
                                      "Updated Successfully"
                                    );
                                    N_getUserProfile(token).then((res) => {
                                      if (res?.params?.profile_info) {
                                        setUser(res.params.profile_info);
                                      }
                                    });
                                  } else {
                                    NotificationManager.success(res.message);
                                  }
                                });
                              }}
                            >
                              {updateloading ? (
                                <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                              ) : null}
                              <span id="reg">Verify OTP</span>
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </form>
                    <div className="text-center py-2">
                      <Link to="/profile" className="text-muted">
                        {" "}
                        Back to Profile
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
