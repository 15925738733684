import React, { useState } from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import {
  isCpass,
  isEmail,
  isPass,
} from "./redux/helpers/form-validator.functions";
import { useDispatch, useSelector } from "react-redux";
import { user_Register } from "./redux/actions/authActions";
import $ from "jquery";
import { NotificationManager } from "react-notifications";

export default function Register(props) {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confPassword, setConfPassword] = useState();
  const [referral_code, setReferral] = useState(
    props?.match?.params?.id ? props?.match?.params?.id : ""
  );
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState();
  const { webData } = useSelector((state) => state.websiteDBReducer);
  if (isLoggedIn) props.history.replace("/");
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
    } else {
      _idv.setAttribute("type", "text");
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();

    if (isEmail(email) && isPass(password) && isCpass(confPassword)) {
      if (agree) {
        $(".main_terms").removeClass("border-danger");
        setLoading(true);
        dispatch(
          user_Register(
            email,
            password,
            confPassword,
            referral_code,
            () => {
              setLoading(false);
              props.history.replace("/otp");
            },
            () => setLoading(false)
          )
        );
      } else {
        NotificationManager.error("Please Agree with term condition !");
        $(".main_terms").addClass("border-danger");
      }
    }
  };
  return (
    <>
      <Header {...props} />

      <div class="container-fluid">
        <div class="row no-gutter">
          <div class="col-md-6 d-none d-md-flex bg-image text-center">
            <img src="/img/register_w.svg" className="login-img" />
          </div>

          <div class="col-md-6 bg-login">
            <div class="login d-flex align-items-center py-5">
              <div class="container">
                <div class="row">
                  <div class="col-lg-10 col-xl-7 m-auto signupform">
                    <h3 class="mt-3"> Signup to {webData.website_title}</h3>
                    <p class="text-muted mb-4 mt-0">
                      Create a account to enjoy trading.
                    </p>
                    <form
                      className=""
                      method="post"
                      id="signupform"
                      onSubmit={onSubmit}
                    >
                      <div className="form-group">
                        <small id="msg">Error Message</small>
                      </div>
                      <div className={`form-group`}>
                        {/* <label htmlFor="user_email">EMAIL</label> */}
                        <input
                          type="email"
                          name="user_email"
                          required=""
                          value={email}
                          onChange={(e) => setEmail(e.target.value.toLowerCase())}
                          className="form-control"
                          id="user_email"
                          placeholder="example@gmail.com"
                        />
                        <i className="fas fa-check-circle"></i>
                        <i className="fas fa-exclamation-circle"></i>
                        <small></small>
                      </div>
                      <div className={`form-group`}>
                        {/* <label htmlFor="user_password">PASSWORD</label> */}
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            name="user_password"
                            required=""
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            id="pass"
                            placeholder="Password"
                          />
                          <span className="input-group-text">
                            <a
                              href="#view_qr"
                              className=""
                              onClick={(e) => {
                                hsPassword("pass");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#666"
                                className="bi bi-eye"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                              </svg>
                            </a>
                          </span>
                        </div>
                        {/* <i className="fas fa-check-circle"></i> */}
                        {/* <i className="fas fa-exclamation-circle"></i> */}
                        <div className="small text-muted mb-3">
                          Password must be contain atleast 1 Capital charcter ,
                          1 digit , 1 symbol and length should be greater than
                          8.
                        </div>
                        <small
                          id="passerr"
                          className="text-danger"
                          // style={{ top: "53%" }}
                        ></small>
                      </div>
                      <div className={`form-group`}>
                        {/* <label htmlFor="user_cpassword">CONFIRM PASSWORD</label> */}
                        <div className="input-group mb-3">
                          <input
                            type="password"
                            className="form-control"
                            name="user_cpassword"
                            id="cpass"
                            required=""
                            value={confPassword}
                            onChange={(e) => setConfPassword(e.target.value)}
                            placeholder="Confirm Password"
                          />
                          <span className="input-group-text">
                            <a
                              href="#view_qr"
                              className=""
                              onClick={(e) => {
                                hsPassword("cpass");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#666"
                                className="bi bi-eye"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                              </svg>
                            </a>
                          </span>
                        </div>
                        {/* <i className="fas fa-check-circle"></i> */}
                        {/* <i className="fas fa-exclamation-circle"></i> */}
                        <small id="cpasserr" className="text-danger"></small>
                      </div>
                      <div className={`form-group`}>
                        {/* <label htmlFor="refercode">
                          REFERRAL CODE (OPTIONAL)
                        </label> */}
                        <input
                          type="text"
                          className="form-control"
                          name="refercode"
                          id="refercode"
                          required=""
                          value={referral_code}
                          onChange={(e) => setReferral(e.target.value)}
                          placeholder="Referral code"
                        />
                        {/* <i className="fas fa-check-circle"></i>
                        <i className="fas fa-exclamation-circle"></i>
                        <small></small> */}
                      </div>
                      <div className="d-flex mt-2">
                        <input
                          className=""
                          type="checkbox"
                          id="agree_terms"
                          name="agree_terms"
                          checked={agree}
                          onChange={(e) => setAgree(e.target.checked)}
                        />
                        <span className="terms">
                          I agree to {webData.website_title}'s
                          <span className="ms-1 terms_condition">
                            <a
                              href="https://ctskola.io/termsandconditions.pdf"
                              target="_blank"
                            >
                              Terms of Service
                            </a>{" "}
                          </span>
                        </span>
                      </div>
                      <div className="d-grid mt-3">
                        <button
                          type="submit"
                          id="btn_submit"
                          className="btn btn-primary btn-lg"
                        >
                          {loading ? (
                            <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                          ) : null}
                          <span id="reg">REGISTER</span>
                        </button>
                      </div>
                      <div className="text-center text-muted mt-2">
                        Already have an account ?
                        <Link className="text-primary" to="/login">
                          {" "}
                          Login{" "}
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
