import React from 'react'
import { useSelector } from 'react-redux';
import depositWithdrwData from './json/deposotWithdraw.json'
export default function DepositWithdrewFees() {
    const { switch_theme } = useSelector(
        (state) => state.AuthReducer
      );
  return (
    <>
         <div className="pt-2 fees-info-table table-responsive">
                <table className={switch_theme == "dark" ? "table table-dark table-striped":"table table-light table-striped"}>
                  <thead>
                    <tr>
                      <th scope="col">ASSET</th>
                      <th scope="col">NETWORK</th>
                      <th scope="col">CONFIRMATIONS</th>
                      <th scope="col">WITHDRAW FEE</th>
                      <th scope="col">MIN. WITHDRAW AMOUNT</th>
                      <th scope="col">MAX. WITHDRAW AMOUNT</th>
                      <th scope="col">DEPOSIT</th>
                      <th scope="col">WITHDRAW</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {depositWithdrwData?.map((item,i)=>{
                        return (
                            <>
                            <tr key={i+"ASD"}>
                            <td scope="row">{item?.token_name}</td>
                            <td>{item?.token_network}</td>
                            <td>{item?.confirmations}</td>
                            <td>{item?.withdraw_fee}</td>
                            <td>{item?.min_withdraw_amount}</td>
                            <td>{item?.min_withdraw_amount}</td>
                           {item?.withdraw==""?
                           <td colspan="2">
                                <div className={ item?.deposit == "Enabled" ? switch_theme == "dark" ? "badges-custom-success-dark":"badges-custom-success": item?.deposit == "Disabled"?"badges-custom-danger":"badges-custom-blue"}>{item?.deposit}</div>
                            </td>
                           : (<>
                            <td>
                                {" "}
                                <div className={ item?.deposit == "Enabled" ? switch_theme == "dark" ? "badges-custom-success-dark":"badges-custom-success": item?.deposit == "Disabled"?"badges-custom-danger":"badges-custom-blue"}>{item?.deposit}</div>
                            </td>
                            <td colspan="2">
                                {" "}
                                <div className={ item?.withdraw == "Enabled" ? switch_theme == "dark" ? "badges-custom-success-dark":"badges-custom-success": item?.withdraw == "Disabled"?"badges-custom-danger":"badges-custom-blue"}>{item?.withdraw}</div>
                            </td>
                           </>)}
                           
                            </tr>
                            </>
                        )
                    })}
                  </tbody>
                </table>
              </div>
    </>
  )
}
