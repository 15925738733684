import React, { useEffect, useState } from "react";

import Banner from "./HomeComp/Banner";
import MarketTrend from "./HomeComp/MarketTrend";
import SocialMedia from "./HomeComp/SocialMedia";
import Header from "./components/Header";
import Footer from "./HomeComp/Footer";

export default function Index(props) {
  const [ispup, setIspup] = useState(true);
  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/61826b076bb0760a4940ed61/1fjin45kt";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return (
    <div className="">
      {/* {ispup?
        <div
            style={{
              position: "fixed",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              zIndex: 200,
              backgroundColor: "rgba(0,0,0,0.5)"
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "20%",
                zIndex: 1000,
                // boxShadow: "2px 2px 20px rgba(0,0,0,0.4)",
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                backgroundColor:"#fafafa",
                width:"auto",
                borderRadius:"3px",
                padding:"2rem",
                color:"#000"
              }}
            >
            
              <div className="container shead-bg">
              <div class="modal-header my-3">
        <h5 class="modal-title" id="exampleModalLabel">Notice</h5>
        <button type="button" onClick={()=>{
          setIspup(false)
        }} class="btn fs-3" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-sm-12">
                    <img src="/img/images.jpeg" className="img-fluid"/>
                   <h2 className="my-2">Website is under maintenence</h2>
                   <p>Please have patience till tomorrow </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :null} */}
       <Header {...props} />
      <Banner />
      <MarketTrend />
      {/* <StartTrade /> */}
      <SocialMedia/>

      <Footer />
    </div>
  );
}
