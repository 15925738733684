import React, { useState, useEffect } from "react";
import "./exside.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  sortByChange,
  sortByPair,
  sortByVol
} from "../redux/helpers/helper_functions";
import {
  N_getSupportedCurrency,
  N_RemoteStatusAll
} from "../redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import { SET_PAIRED_CURRENCY_PRICE } from "../redux/constant";
import { BiBitcoin } from "react-icons/bi";

export default function ExSide(props) {
  const coin = props.match.params.id.split("-");
  const [active, setActive] = useState(coin[1] ? coin[1] : "inr");
  const [data, setData] = useState([]);
  const [change, setChange] = useState(true);
  const [searchTxt, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [paired_cur, pairedCur] = useState([]);
  const [remoteCoins, setRemoteCoins] = useState([]);
  let { coins, user_fav_pairing } = useSelector((state) => state.coinDBReducer);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  let coins_data = Object.values(coins);
  const { user } = useSelector((state) => state.AuthReducer);
  const [pc_price, setpc_price] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    N_getSupportedCurrency(user?.params ? user.params.token : user.token)
      .then((data) => {
        if (!data.error && data) {
          // console.log("prdc: ", data);
          pairedCur(data);
        } else {
          console.log(data);
          NotificationManager.error(data.message);
        }
      })
      .catch((e) => {
        console.log("error in getcrnc: ", e);
      });
    N_RemoteStatusAll()
      .then((data) => {
        if (data.status == 200) {
          setRemoteCoins(data.remoteTradingData);
        }
      })
      .catch((e) => {
        console.log("error in N_RemoteStatusAll: ", e);
      });
  }, []);

  useEffect(() => {
    let dd = coins_data.filter((d) => {
      if (d.symbol !== active.toUpperCase()) return d;
    });
    setData(dd);
  }, [coins_data.length, coins]);

  useEffect(() => {
    let dd;
    if (searchTxt != "") {
      dd = Object.values(coins_data).filter((d) => {
        if (d.symbol.startsWith(searchTxt?.toUpperCase())) return d;
      });
    } else {
      dd = Object.values(coins_data).filter((d) => {
        if (d.symbol !== active.toUpperCase()) return d;
      });
    }
    setData(dd);
  }, [searchTxt, active]);

  useEffect(() => {
    let ddt = {};
    coins_data?.map((d, i) => {
      for (let i = 0; i < paired_cur.length; i++) {
        if (d.symbol === paired_cur[i].currency_coin) {
          ddt[d.symbol] = d.current_price_inr;
        }
      }
    });
    dispatch({ type: SET_PAIRED_CURRENCY_PRICE, data: ddt });
    setpc_price(ddt);
  }, [paired_cur]);

  return (
    <div className="pt-1 eth-market">
      <div class="nav nav-pills nav-justified" id="myTab" role="tablist">
        <button
          className={`nav-link ${active == "fav" ? "active" : ""} `}
          id="pln_currency"
          onClick={() => setActive("fav")}
          name="pln_currency"
        >
          <i className="fa fa-star text-warning"></i>{" "}
        </button>

        {paired_cur
          ? paired_cur.map((item, i) => (
              <>
                <button
                  id={i * 2 + 5}
                  className={`exide-bg nav-link  ${
                    active == item.currency_coin.toLowerCase() ? "active" : ""
                  } `}
                  onClick={() => setActive(item.currency_coin.toLowerCase())}
                  name="pln_currency"
                >
                  {item.currency_coin}
                </button>
              </>
            ))
          : null}
      </div>

      <div className="mb-1">
        <div className="input-group exchange_input_grp">
          <input
            type="text"
            className="form-control text-start border-start-0 rounded-0 mp-exide-form bg-white"
            aria-label="Text input with checkbox"
            placeholder="Search "
            value={searchTxt}
            onChange={(e) => setSearch(e.target.value.toUpperCase())}
          />
          <span className="input-group-text border-end-0 rounded-0">
            <div className="">
              <i className="fa fa-search"></i>
            </div>
          </span>
        </div>
      </div>

      <div className="sidebar-overflow custom_scrollbar" id="style-3">
        <table
          id="coins_table"
          className="exchange_tbl order-book-table table-layout table_striped tablesorter"
          //style={{ height: "auto" }}
        >
          <thead>
            <tr className="border-top-0">
              <th
                onClick={() => {
                  if (sort == "pair-asc") {
                    sortByPair(data, true, (result) => {
                      setData(result);
                      setChange(!change);
                      setSort("pair-dsc");
                    });
                  } else {
                    sortByPair(data, false, (result) => {
                      setData(result);
                      setChange(!change);
                      setSort("pair-asc");
                    });
                  }
                }}
              >
                <h6>Pair</h6>
                {sort == "pair-asc" ? (
                  <i
                    className="fa fa-arrow-up"
                    aria-hidden="true"
                    style={{ fontSize: "10px" }}
                  ></i>
                ) : null}
                {sort == "pair-dsc" ? (
                  <i
                    className="fa fa-arrow-down"
                    aria-hidden="true"
                    style={{ fontSize: "10px" }}
                  ></i>
                ) : null}
              </th>
              <th
                onClick={() => {
                  if (sort == "vol-asc") {
                    sortByVol(data, true, (result) => {
                      setData(result);
                      setChange(!change);
                      setSort("vol-dsc");
                    });
                  } else {
                    sortByVol(data, false, (result) => {
                      setData(result);
                      setChange(!change);
                      setSort("vol-asc");
                    });
                  }
                }}
              ></th>
              <th>
                <h6>Vol</h6>
                {sort == "vol-asc" ? (
                  <i
                    className="fa fa-arrow-up"
                    aria-hidden="true"
                    style={{ fontSize: "10px" }}
                  ></i>
                ) : null}
                {sort == "vol-dsc" ? (
                  <i
                    className="fa fa-arrow-down"
                    aria-hidden="true"
                    style={{ fontSize: "10px" }}
                  ></i>
                ) : null}
              </th>
              <th
                className="text-end"
                //   style={{ cursor: "pointer", outline: "none" }}
                onClick={() => {
                  if (sort == "change-asc") {
                    sortByChange(data, true, (result) => {
                      setData(result);
                      setChange(!change);
                      setSort("change-dsc");
                    });
                  } else {
                    sortByChange(data, false, (result) => {
                      setData(result);
                      setChange(!change);
                      setSort("change-asc");
                    });
                  }
                }}
              >
                <h6>Change</h6>
                {sort == "change-asc" ? (
                  <i
                    className="fa fa-arrow-up"
                    aria-hidden="true"
                    style={{ fontSize: "10px" }}
                  ></i>
                ) : null}
                {sort == "change-dsc" ? (
                  <i
                    className="fa fa-arrow-down"
                    aria-hidden="true"
                    style={{ fontSize: "10px" }}
                  ></i>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {active != "fav"
              ? data?.map((d, index) => {
                  if (active.toUpperCase() != d.symbol.toUpperCase())
                    return (
                      <>
                        {active.toUpperCase() !== "BTC" &&
                        active.toUpperCase() !== "INR" &&
                        active.toUpperCase() !== "USDT" ? (
                          d["is_paired_custom_coin"] === 1 ? (
                            <CoinRow
                              {...d}
                              id={index * 5}
                              pairing_currency={active}
                              pc_price={pc_price}
                              remoteCoins={remoteCoins}
                            />
                          ) : null
                        ) : d[`is_paired_${active.toLowerCase()}`] === 1 ? (
                          <CoinRow
                            {...d}
                            id={index * 2 * 5}
                            pairing_currency={active}
                            pc_price={pc_price}
                            remoteCoins={remoteCoins}
                          />
                        ) : null}
                      </>
                    );
                  else return <></>;
                })
              : user_fav_pairing?.map((d, index) => (
                  <CoinRow {...coins[d]} key={index * 7} />
                  // console.log("fav_coin",)
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function CoinRow(props) {
  const history = useHistory();
  let price = 0;
  let coinStatus = false;
  // console.log("check symbol_1: ", props);
  let cp = props.pairing_currency.toUpperCase();
  let remoteArray = props.remoteCoins.find((item) => {
    if (item.currency_type == props.symbol && item.compare_currency == cp)
      return item;
  });
  coinStatus = remoteArray?.update_price;
  switch (props.pairing_currency.toUpperCase()) {
    case "INR":
      price = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 8,
        maximumSignificantDigits: 8
      }).format(
        coinStatus
          ? props.inr_price
          : props.raw_current_price_inr
          ? props.raw_current_price_inr
          : 0.12
      );

      break;

    case "USDT":
      price = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 8,
        maximumSignificantDigits: 8
      }).format(
        coinStatus
          ? props.usdt_price
          : props.raw_current_price_inr
          ? props.raw_current_price_inr / props.pc_price.USDT
          : 0.03
      );

      break;
    case "BTC":
      price = coinStatus
        ? props.btc_price
        : props.raw_current_price_inr
        ? Number(props.raw_current_price_inr / props.pc_price.BTC).toFixed(8)
        : 0.03;
      // console.log("check symbol_2: ", props.symbol, price);
      break;
    case "RBC":
      price = coinStatus
        ? props.rbc_price
        : props.raw_current_price_inr
        ? Number(props.raw_current_price_inr / props.pc_price.RBC).toFixed(8)
        : 0.03;
      break;
    default:
      price = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "USD"
      }).format(
        props.raw_current_price_inr ? props.raw_current_price_inr : 0.03
      );
  }
  return (
    <tr
      id={props.id}
      style={{ cursor: "pointer", overflow: "hidden" }}
      onClick={() => {
        // dispatch(
        //   changeActiveCoin(props.symbol.toLowerCase(), props.active_tab, () =>
        localStorage.setItem(
          "precoinpair",
          `/exchange/${props.symbol.toLowerCase()}-${props.pairing_currency.toLowerCase()}`
        );
        history.push(
          `/exchange/${props.symbol.toLowerCase()}-${props.pairing_currency.toLowerCase()}`
          //   )
          // )
        );
      }}
    >
      <td colSpan="2">
        <div className="d-flex align-items-center">
          <img
            src={props.icon}
            alt={props.symbol}
            width="15"
            className="me-2"
            height="15"
          />
          <div className="">
            <span className="fs-11 text-muted">
              {props.symbol}/{props.pairing_currency?.toUpperCase()}
            </span>
          </div>
        </div>
      </td>
      <td>
        <span className="">
          <span
            className={
              props.direction_inr == "up" ? "text-success" : "text-danger"
            }
            style={{ fontSize: "11px" }}
          >
            <i
              className={
                props.direction_inr == "up"
                  ? "fa fa-caret-up"
                  : "fa fa-caret-down"
              }
            ></i>{" "}
            {props.price_change_percentage_1h_inr}%
          </span>
        </span>
      </td>
      <td className="text-end">
        <p className="no-margin text-fade fs-11">
          {props.pairing_currency?.toUpperCase() === "BTC" ? (
            <>
              <BiBitcoin size={12} />
              {price}
            </>
          ) : (
            price
          )}
        </p>
      </td>
    </tr>
  );
}
