import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./login.css";
import { isEmail, isPass } from "./redux/helpers/form-validator.functions";
import { user_Login, user_authentication } from "./redux/actions/authActions";
import {
  N_getGoogleAuth,
  N_sendOTPMobileEmail
} from "./redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";

export default function Login(props) {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  if (isLoggedIn) props.history.replace("/");
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const [eye, seteye] = useState(false);
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye(true);
    }
  };
  const submit = (e) => {
    e.preventDefault();
    if (isEmail(email) && isPass(password)) {
      N_getGoogleAuth(email, password).then((data) => {
        if (data.status === 200 && data.params.authenticator_status === 0) {
          setLoading(true);
          dispatch(
            user_Login(
              email,
              password,
              () => {
                setLoading(false);
                props.history.replace("/otp");
              },
              () => setLoading(false)
            )
          );
        } else {
          console.log("called");
          if (data.status === 200 && data.params.authenticator_status === 1) {
            N_sendOTPMobileEmail(email, data.params.mobile_no);
            dispatch(
              user_authentication(
                email,
                password,
                data.params.authenticator_status,
                data.params.mobile_no,
                () => {
                  setLoading(false);
                  props.history.replace("/authenticator");
                }
              )
            );
          } else if (
            data.status === 200 &&
            data.params.authenticator_status === 2
          ) {
            dispatch(
              user_authentication(
                email,
                password,
                data.params.authenticator_status,
                data.params.authenticator_key,
                () => {
                  setLoading(false);
                  props.history.replace("/authenticator");
                }
              )
            );
          } else if (data.status === 400) {
            NotificationManager.error(data.message);
          }
        }
      });
    }
  };

  // useEffect(() => {
  //   const notice = document.getElementById("notice");
  //   function startBlinking() {
  //     setInterval(function () {
  //       blink();
  //     }, 2000);
  //   }
  //   function blink() {
  //     // note no timeout for the hiding part
  //     notice.style.display = "none";
  //     setTimeout(function () {
  //       notice.style.display = "block";
  //     }, 1000);
  //   }

  //   startBlinking();
  // }, []);

  return (
    <>
      <Header {...props} />
      <div class="container-fluid">
        <div class="row no-gutter">
          <div class="col-lg-6 d-none d-md-flex bg-primary text-center">
            <img src="/img/login_w.svg" className="login-img" />
          </div>

          <div class="col-md-6 bg-login">
            <div class="login d-flex align-items-center py-5">
              <div class="container">
                <div class="row">
                  <div class="col-lg-10 col-xl-7 m-auto signupform">
                    <h3 class="mt-3">
                      {" "}
                      Signin to{" "}
                      <span className="text-uppercase">
                        {" "}
                        {webData.website_title}
                      </span>
                    </h3>
                    <p class="text-muted mb-4 mt-0">
                      Sign In your account to enjoy trading.
                    </p>
                    <form
                      className=""
                      method="post"
                      id="loginForm"
                      onSubmit={submit}
                      autoComplete="off"
                    >
                      <div className="form-group">
                        <small
                          id="msg"
                          className="mt-2 "
                          style={{ fontSize: "15px", marginTop: "20px" }}
                        >
                          Error Message
                        </small>
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          name="user_email"
                          className="form-control"
                          required=""
                          value={email}
                          id="user_email"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="example@gmail.com"
                        />
                        <i className="fas fa-check-circle"></i>
                        <i className="fas fa-exclamation-circle"></i>
                        <small></small>
                      </div>
                      <div className="form-group mb-1">
                        {/* <label htmlFor="user_password" className="">
                    PASSWORD
                  </label> */}
                       
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            name="user_password"
                            id="pass"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required=""
                            placeholder="Password"
                          />
                          <span className="input-group-text">
                            <a
                              href="#view_qr"
                              className=""
                              onClick={(e) => {
                                hsPassword("pass");
                              }}
                            >
                              {/* <i className="far fa-eye"></i> */}
                              {eye ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#666"
                                  className="bi bi-eye-slash-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#666"
                                  className="bi bi-eye-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>
                              )}
                            </a>
                          </span>
                        </div>
                        <i className="fas fa-check-circle"></i>
                        <i className="fas fa-exclamation-circle"></i>
                        <small id="passerr"></small>

                      
                      </div>
                      <div className="text-end">
                      <Link to="/forget" className="text-primary">
                          Forgot Password
                        </Link>
                      </div>

                      <div className="d-grid mt-2">
                        <button
                          type="submit"
                          id="login_btn"
                          className="btn btn-primary btn-lg"
                        >
                          {loading ? (
                            <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                          ) : null}
                          <span id="reg">LOGIN</span>
                        </button>
                      </div>
                      <div className="text-center mt-1">
                        <span className="text-muted">
                          {" "}
                          Don't have account?{" "}
                        </span>
                        <Link className="text-primary" to="/create">
                          Register
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
