import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import { N_getUserProfile, N_updateName } from "./redux/helpers/api_functions_new";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { checkName } from "./redux/helpers/helper_functions";
export default function UserProfile(props) {
  const { user } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = React.useState(true);
  const [profile, setProfile] = React.useState({});
  const [isnameupdate, setIsNameUpdate] = useState(false);
  const [username, setName] = useState('');
  useEffect(() => {
    N_getUserProfile(user?.params ? user.params.token : user.token)
      .then((d) => {
        // console.log("userProfile",d?.params.profile_info);
        if (d.status === 200) {
          setProfile(d?.params.profile_info);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <>
      <Header {...props} />

      <ProfileSidebar {...props} />

      <div className="main-content">
        <div className="row mt-3">
          <div className="col-lg-12 m-auto">
            <div class="profile-card">
              <div class="image">
                {!loading && profile?.name != "" ? (
                  <img
                    src="/img/account_profile.png"
                    style={{ width: "130px", height: "130px" }}
                    class="profile-img"
                  />
                ) : null}
                {/* <img
                      src="https://tinypic.host/images/2022/12/19/img_avatar.png"
                      alt=""
                      class="profile-img"
                    /> */}
              </div>

              <div class="text-data">
                <span class="name mb-2 text-center">
                  Hello!  {isnameupdate? 
                  <div className="input-group"> 
                    <input 
                    type="text"
                    className="form-control"
                    value={username} 
                  onChange={(e)=>{
                    setName(e.target.value)
                  }}
                  />
                  <div class="input-group-append">
                <button type="button" className="btn btn-danger" style={{    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0}}
                  onClick={(e)=>{
                    if(checkName(username)) {
                      setIsNameUpdate(!isnameupdate)
                      N_updateName(user?.params ? user.params.token : user.token, username)
                      .then((d)=>{
                        N_getUserProfile(user?.params ? user.params.token : user.token)
                          .then((d) => {
                            // console.log("userProfile",d?.params.profile_info);
                            if (d.status === 200) {
                              setProfile(d?.params.profile_info);
                            }
                            setLoading(false);
                          })
                          .catch((e) => {
                            console.log(e);
                          });
                      })
                    } else {
                      NotificationManager.error("Please Enter Your Name")
                    }
                    
                  }}>update</button>
               </div>
                    </div>
                 :
                  (
                    <span className="text-capitalize">
                    {" "}
                    {profile.name ? profile.name : "Not Saved"}{" "}
                    <Link className="text-underline me-2" 
                    onClick={()=>{
                      setIsNameUpdate(!isnameupdate)
                    }}>
                      <i className="fa ms-2 fa-pencil-square-o"></i>
                    </Link>
                  </span>
                  )}
                  {/* {!loading && profile?.name != "" ? (
                    <span className="text-capitalize">
                      {" "}
                      {profile.name ? profile.name : "Not Saved"}{" "}
                      <Link className="text-underline me-2" 
                      onClick={()=>{
                        setIsNameUpdate(!isnameupdate)
                      }}>
                        <i className="fa ms-2 fa-pencil-square-o"></i>
                      </Link>
                    </span>
                  ) :  null} */}
                </span>
                <span class="email mb-2">
                  {!loading && profile?.email != "" ? (
                    <div className="d-flex align-items-center">
                      <div className="me-1">
                        {" "}
                        {profile.email ? profile.email : "Please login again"}
                      </div>
                      <Link className="text-underline me-2" to="/edit_email">
                        <i className="fa ms-2 fa-pencil-square-o"></i>
                      </Link>
                    </div>
                  ) : null}
                </span>

                <div>
                  {!loading && profile?.mobile_number != "" ? (
                    <div className="mb-2 d-flex align-items-center">
                      <i className="fa me-2 fa-phone"></i>
                      <div className="me-1">
                        {profile.mobile_number > 1
                          ? "+91 " + profile.mobile_number
                          : "Not Saved"}
                      </div>
                      <Link className="text-underline me-2" to="/edit_mobile">
                        <i className="fa ms-2 fa-pencil-square-o"></i>
                      </Link>
                    </div>
                  ) : null}
                  {!loading && profile?.mobile_number == "" ? (
                    <div className="">
                      {/* <button
                        className="btn btn-primary mt-2"
                        onClick={() => props.history.push("/mobile-verify")}
                      >
                        Verify Your Mobile
                      </button> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
